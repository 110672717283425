// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import differenceWith from 'lodash/differenceWith';
import matches from 'lodash/matches';
import isEqual from 'lodash/isEqual';
import * as c from '../../routes/consts.js';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder, {
  ValidationResult,
} from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import debugLogger, * as log from '../../lib/debug';
import * as REGEX from '../../data/regex.js';
import { difference, intersection, intersectionBy } from '../../utils/lodash';
import FormPortUsage from '../../containers/generic/MetaForm/FormPortUsage';
import { isNil } from 'lodash';
import FormCustomServerConnections from '../../containers/generic/MetaForm/FormCustomServerConnections.js';
import { LABEL_RACKTEMPLATES } from '../../components/HybridNav/consts.js';

const debug = debugLogger('RackTemplate', log.LOG_LEVEL_WARN);

const EXTERNAL_NAME = c.URL_RACKTEMPLATES;
const DS_POD_TYPES = 'podtypes';

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
  homeLabel: LABEL_RACKTEMPLATES,
};

const DS_RACK_TEMPLATE = 'racktemplate';
const DS_SWITCH_TYPES = 'switch';

function metaFabricAdd(props) {
  const m = new MetaBuilder(props);
  if (m.parent.namespace == 'template_config') {
    m.ReadOnly();
  }

  const dsSwitchTypes = m.newDataSource(c.URL_SWITCHTYPES, DS_SWITCH_TYPES);
  const dsPodTypes = m.newDataSource(c.URL_PODTYPES, DS_POD_TYPES);
  const dsServerPortsAvail = m.newStaticDataSource([]);
  const dsUplinkPortsAvail = m.newStaticDataSource([]);

  const portsAvailMap = new Map();
  const portsAvail = getAvailablePorts(m.view, dsSwitchTypes.data);

  const updateAvailPorts = (form) => {
    // selected ports from the form
    const { port_usage2 } = form;

    const server_ports = port_usage2 ? port_usage2.server_ports : [];
    const uplink_ports = port_usage2 ? port_usage2.uplink_ports : [];

    // comparators
    // NOTE: assumption here is that subport names are in the form of "N-1" or
    // "N.1".  This won't work for any other naming convention.  Ideally, should
    // be checking for explicit parent/child port names in SwitchType definition.
    const removeSubPortsAndParentPorts = (a, pid) => {
      if (a.id.startsWith(`${pid}-`) || a.id.startsWith(`${pid}.`)) return true; // remove subport

      if (typeof pid === 'string') {
        let idx = pid.indexOf('-');

        if (idx !== -1) {
          if (a.id === pid.substr(0, idx)) return true; // remove parent port
        } else {
          idx = pid.indexOf('.');
          if (idx !== -1) {
            if (a.id === pid.substr(0, idx)) return true; // remove parent port
          }
        }
      }
      return false;
    };

    const removeOtherPorts = (a, pid) => {
      if (a.id === pid) return true; // remove exact match
      return removeSubPortsAndParentPorts(a, pid);
    };

    // remove ports (and sub-ports) already selected
    let server_ports_avail = difference(
      getAvailablePorts(m.view, dsSwitchTypes.data),
      server_ports,
      removeSubPortsAndParentPorts
    );

    server_ports_avail = difference(
      server_ports_avail,
      uplink_ports,
      removeOtherPorts
    );

    dsServerPortsAvail.SetData(server_ports_avail);

    let uplink_ports_avail = difference(
      getAvailablePorts(m.view, dsSwitchTypes.data),
      uplink_ports,
      removeSubPortsAndParentPorts
    );

    uplink_ports_avail = difference(
      uplink_ports_avail,
      server_ports,
      removeOtherPorts
    );

    dsUplinkPortsAvail.SetData(uplink_ports_avail);
  };

  m.formDefaults((form) => {
    // default form values if not present
    form.server_ports = form.server_ports || [];
    form.uplink_ports = form.uplink_ports || [];

    // remove any previously selected ports that are no longer available, e.g. due to switch types being selected/unselected
    form.server_ports = intersection(
      form.server_ports,
      portsAvail,
      (a, b) => a === b.id
    );

    form.uplink_ports = intersection(
      form.uplink_ports,
      portsAvail,
      (a, b) => a === b.id
    );

    // remove available ports already in use across server and uplink multi-select options
    updateAvailPorts(form);
  });

  // if edit, then remove item being edited from unique set
  let otherFabrics = m.parentView.stateDefaultTo('form.switch_sets', []);
  const origForm = m.view.prop('formData', false);
  if (!isNil(origForm)) {
    otherFabrics = differenceWith(otherFabrics, [origForm], isEqual);
  }

  debug.debug('metaFabricAdd: form.fabrics', otherFabrics);

  m.addField('name', 'Name')
    .Input()
    .Required()
    .MaxLength(150)
    .MaxWidth(mb.SMALL)
    .UniqueIn(otherFabrics)
    .OnChange((val, oldVal) => {
      // auto populate switchnames if empty or they match the previous computed value
      const oldNameA = m.view.state('form.switch_name_suffix[0]', false);
      const oldCompNameA = `${oldVal}-A`;

      if (!oldNameA || oldNameA == oldCompNameA) {
        m.view.setFormValue('switch_name_suffix[0]', `${val}-A`);
      }

      const oldNameB = m.view.state('form.switch_name_suffix[1]', false);
      const oldCompNameB = `${oldVal}-B`;

      if (!oldNameB || oldNameB == oldCompNameB) {
        m.view.setFormValue('switch_name_suffix[1]', `${val}-B`);
      }
    });

  m.addField('fabric_type', 'Type')
    .Input()
    .Required()
    .DropDown()
    .MaxWidth(mb.SMALL)
    .DataXform(dsPodTypes, (json) =>
      json.map((t) => ({ id: t.id, name: t.name, info: t.description }))
    );

  m.addField('ha', 'HA (Dual switch)')
    .Input()
    .CheckBox(false)
    .MaxWidth(mb.SMALL);

  m.addField('switch_types', 'Switch types')
    .Input()
    .MultiSelect()
    .Required()
    .MaxWidth(mb.SMALL)
    .DataXform(dsSwitchTypes, (json) =>
      json.map((t) => ({ id: t.id, name: t.name }))
    )
    .OnInit(() => {
      updateAvailPorts(m.view.getForm());
    })
    .OnChange(() => {
      portsAvailMap.clear();
      updateAvailPorts(m.view.getForm());
      getAvailablePorts(m.view, dsSwitchTypes.data).forEach((p) => {
        portsAvailMap.set(p.id, 1);
      });
    });

  m.addField('switch_name_suffix[0]', 'Switch A Name (suffix)')
    .Input()
    .Required()
    .MaxWidth(mb.SMALL)
    .OnChange((value) => {
      const { switch_name_suffix } = m.view.getForm();
      const old_switch_name_suffix = switch_name_suffix || [null, null];
      const newValue = [value, old_switch_name_suffix[1]];
      m.view.setFormValue('switch_name_suffix', newValue);
    });

  m.addField('switch_name_suffix[1]', 'Switch B Name (suffix)')
    .Input()
    .RequiredSometimes(() => m.view.getForm()?.ha)
    .Visible(() => m.view.getForm()?.ha)
    .OnChange((value) => {
      const { switch_name_suffix } = m.view.getForm();
      const old_switch_name_suffix = switch_name_suffix || [null, null];
      const newValue = [old_switch_name_suffix[0], value];
      m.view.setFormValue('switch_name_suffix', newValue);
    });

  // Port Usage section
  m.addSection('Port usage').MaxWidth(mb.LARGE);

  m.addField('port_usage2', 'Port usage').Input().Custom(FormPortUsage, {
    dsSwitchTypes,
  });

  // Custom Server Connections section
  m.addSection('Custom server connections').MaxWidth(mb.LARGE);

  m.addField('custom_server_conns', 'Custom server connections')
    .Input()
    .Custom(FormCustomServerConnections, {
      dsSwitchTypes,
    });

  m.addSection('Local ID pools');

  m.addField('reserved_vlans', 'Reserved VLAN IDs')
    .Input('3800:3899')
    .MaxWidth(mb.SMALL)
    .Required()
    .RegEx(REGEX.VLAN_POOL, REGEX.VLAN_POOL_MSG);

  m.addField('reserved_lags_ids', 'Reserved LAG IDs')
    .Input('1:128')
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.LAG_POOL, REGEX.LAG_POOL_MSG);

  return m;
}

function metaCreate(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    form.custom_network_type = form.custom_network_type || '';
  });

  const id = m.view.prop('itemId', false);

  if (!id) {
    m.newStaticDataSource({}, DS_RACK_TEMPLATE);
  } else {
    m.newDataSource(c.URL_RACKTEMPLATES, DS_RACK_TEMPLATE)
      .Item(id)
      .OnLoad((json) => {
        debug.debug('got rack template:', json);
        m.view.initForm(json.data);
      });
  }

  m.addSection('1').NoChrome().MaxWidth(mb.LARGE);

  m.addColumn('name', 'Name').Input().Required();

  if (props.readOnly) {
    m.addColumn('id', 'ID').Input().ReadOnly();
  }

  m.addColumn('description', 'Description').Input();

  return rackConfigEditMeta(props, m);
}

export function getAvailablePorts(view, switchTypesData) {
  // get intersection of all switch types available ports
  let portsAvail = [];
  const { switch_types = [] } = view.getForm() || [];

  if (switch_types.length && !isNil(switchTypesData)) {
    const switch_type_ids = switchTypesData.filter((s) =>
      switch_types.some((id) => id === s.id)
    );

    for (const switchType of switch_type_ids) {
      const swPortsAvail = switchType.ports.map((p) => ({
        id: p.name,
        name: p.name,
      }));

      if (!portsAvail.length) {
        portsAvail = [...swPortsAvail];
      } else {
        portsAvail = intersectionBy(portsAvail, swPortsAvail, 'id');
      }
    }
  }

  return portsAvail;
}

export function rackConfigEditMeta(props, m) {
  // Map of currently available fabrics
  let availFabrics = [];
  const updateAvailFabrics = (form) => {
    availFabrics = [];

    if (form.switch_sets) {
      form.switch_sets.forEach((fabric) => {
        availFabrics.push(fabric.name);
      });
      // trigger validation update via setState()
      m.view.setFormValue('_temp.availFabrics', availFabrics);
    }
  };

  m.addSection('Fabrics')
    .MaxWidth(mb.XXLARGE)
    .OverviewText('Configure network fabrics to be used');

  const dsRackTemplate = m.getDataSource(DS_RACK_TEMPLATE);
  const fabricTable = m
    .addInputTable('fabrics', 'Fabric')
    .DataXform(dsRackTemplate, (json) => {
      debug.debug('fabricTable:DataXform', dsRackTemplate, json);
      return json.switch_sets || [];
    })
    .OnInit(() => {
      updateAvailFabrics(m.view.state('form'));
    });

  fabricTable.addRowClick((rowData) => () => {});

  const isRack = m.namespace !== 'template_config';

  if (isRack) {
    m.addField('_temp.fabricValidator', '')
      .Input()
      .Hidden()
      .CustomValidator(() => {
        if (availFabrics.length === 0) {
          return new ValidationResult(false, 'Add atleast one fabric');
        }
        return new ValidationResult(true, '');
      });

    fabricTable.AddDialogMeta(metaFabricAdd);
    fabricTable.OnAdd((data) => {
      dsRackTemplate.Push('switch_sets', data);
      m.view.addFormValue('switch_sets', data);
      updateAvailFabrics(m.view.state('form'));
    });

    fabricTable.OnDelete((data) => {
      debug.debug('onDelete(fabrics)', data);
      // remove fabric
      const fabricMatch = matches({ name: data.name });
      dsRackTemplate.Remove('switch_sets', fabricMatch);
      m.view.removeFormValue('switch_sets', fabricMatch);
      updateAvailFabrics(m.view.state('form'));
    });
  }

  fabricTable.EditDialogMeta(metaFabricAdd);
  fabricTable.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(fabrics)', oldData, nextData);
    const fabricMatch = matches({ name: oldData.name });
    dsRackTemplate.Update('switch_sets', fabricMatch, nextData, true);
    m.view.updateFormValue('switch_sets', fabricMatch, nextData, true);
    updateAvailFabrics(m.view.state('form'));
  });

  fabricTable.addField('name', 'Name').CellXform((rowData) => rowData.name);

  fabricTable
    .addField('fabric_type', 'Type')
    .CellXform((rowData) => rowData.fabric_type);

  fabricTable
    .addField('switch_types', 'Switch types')
    .CellXform((rowData) => rowData.switch_types);

  fabricTable
    .addField('ha', 'HA (Dual switch)')
    .CellXform((rowData) => (rowData.ha ? 'Yes' : 'No'));

  fabricTable
    .addField('custom_server_conns', 'Custom server connections')
    .CellXform((rowData) => {
      const value = rowData?.custom_server_conns
        .map((conn) => `${conn.match_value} (${conn.nic_count})`)
        .join(', ');

      return value || [];
    });

  return m;
}

export { default as RackTemplateListView } from './RackTemplateListView';

export const RackTemplateItemView = ItemViewContainer({
  ...settings,
  allowEdit: true,
  meta: (props) => metaCreate(props), // use edit meta
  title: 'Rack template',
});

export const RackTemplateCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create rack template',
});

export const RackTemplateEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Edit rack template',
});
