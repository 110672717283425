// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import { Box, DropButton, FormField, RadioButton, ThemeContext } from 'grommet';
import { CircleQuestion } from 'grommet-icons';
import { SMALL } from '../MetaBuilder';

const FormRadioGroupWithTips = ({
  col,
  value,
  data,
  onChange,
  validationResult = {},
  ...rest
}) => {
  const options = useMemo(() => data, [data]);

  const inputStyle = {
    style: {
      maxWidth: col.maxWidth || col.width || SMALL,
      minWidth: col.minWidth || col.width || SMALL,
    },
  };

  const theme = {
    radioButtonGroup: {
      container: {
        direction: col.direction === 'row' ? 'row-responsive' : 'column',
      },
    },
  };

  return (
    <FormField
      label={col.isRequired ? `${col.displayName}*` : col.displayName}
      name={col.keyPath}
      htmlFor={col.keyPath}
      style={{ gridColumn: '1 / span 2', ...inputStyle.style }}
      error={validationResult.msg}
    >
      <ThemeContext.Extend value={theme}>
        <Box role='radiogroup' gap='small' {...inputStyle} {...rest}>
          {options.map(({ name, tip, id: optionValue }, i) => (
            <Box align='center' direction='row' key={optionValue} gap='xxsmall'>
              <RadioButton
                name={col.keyPath}
                disabled={col.isReadOnly}
                checked={optionValue === value}
                label={name}
                value={optionValue}
                onChange={() => {
                  onChange(col.keyPath, optionValue);
                }}
              />
              {tip && (
                <DropButton
                  dropAlign={{ top: 'bottom', left: 'left' }}
                  dropContent={
                    <Box pad='medium' width='medium'>
                      {tip}
                    </Box>
                  }
                  icon={<CircleQuestion />}
                />
              )}
            </Box>
          ))}
        </Box>
      </ThemeContext.Extend>
    </FormField>
  );
};

export default FormRadioGroupWithTips;
