// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useCallback, useEffect, useState } from 'react';
import { TextInput } from 'grommet';
import debounce from 'lodash/debounce';
import debugLogger, { LOG_LEVEL_DEBUG } from '../../../lib/debug';

const TextInputDebounce = ({ value, onChange, testId, ...rest }) => {
  const debug = debugLogger(`TextInputDebounce::${rest.name}`, LOG_LEVEL_DEBUG);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const debounceKey = useCallback(
    debounce((e) => {
      debug.log('debounceKey', { value: e.target.value, key: e.key });
      onChange(e);
    }, 350),
    [],
  );

  // Handle input change
  const handleChange = (e) => {
    const { value } = e.target;
    debug.log('new text', { value });
    setInputValue(value);
    debounceKey(e);
  };

  return (
    <TextInput
      onChange={handleChange}
      value={inputValue}
      data-testid={testId ?? `debounce-text-input-${rest.name}`}
      {...rest}
    />
  );
};

export default TextInputDebounce;
