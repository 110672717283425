// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { makeRestUrl, URL_MACHINETYPES } from '../routes/consts';
import { renderLink } from './helper';
import { editColumn } from '../utils/editColumn';
import { useMembershipContext } from '../utils/context/membershipContext';

export const columns = (role) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_MACHINETYPES,
    }),
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'description',
  },
  {
    header: 'FW baseline version',
    isDefault: true,
    property: 'fw_baseline_version_default',
  },
  {
    header: 'Weight',
    isDefault: true,
    property: 'weight',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'ID',
    property: 'id',
  },
  ...(role?.isScopePortal?.()
    ? [
        {
          header: 'Published',
          isDefault: true,
          property: 'hoster_published',
          render: ({ hoster_id: id }) => (id === '*' ? 'yes' : 'no'),
        },
      ]
    : []),
  ...editColumn({ base: URL_MACHINETYPES, role }),
];

export const machineTypesMapper = (data) => ({
  ...data,
  immutable: !!data.auto_config?.synced_from_remote,
});

export const getMachineTypes = async () => {
  const url = makeRestUrl(URL_MACHINETYPES);
  const response = await get(url);

  return response.json();
};

export const useGetMachineTypes = () => {
  const { role } = useMembershipContext();
  const queryResult = useQuery({
    queryKey: URL_MACHINETYPES,
    select: (data) => data.map(machineTypesMapper),
    queryFn: getMachineTypes,
  });

  return {
    ...queryResult,
    columns: columns(role).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
