// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { Fragment, useMemo, useState } from 'react';
import isArray from 'lodash/isArray';
import isArrayLike from 'lodash/isArrayLike';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import {
  Box,
  Heading,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from 'grommet';
import { Edit, Add, Trash, Refresh, Up, Down } from 'grommet-icons';
import * as mb from '../MetaBuilder';
import MetaForm from '.';
import { getPath } from '../../../lib/deep';
import { Link, Modal, SearchableSelect } from '../../../components';
import LinksCell from './LinksCell.js';
import ModalControl from '../../../utils/ModalControl';
import IconButton from '../../../components/generic/IconButton.js';
import { URL_RACKS, makeRestItemUrl } from '../../../routes/consts';

const HelpBlock = (props) => <Text color='status-critical' {...props} />;

const getMultiValue = (options, valueCSV) => {
  const multiValue = valueCSV.split(',');

  return options.filter(({ value }) => multiValue.includes(value));
};

const StyledTable = styled(({ col, tcols, trows, ...rest }) => (
  <Table {...rest}>
    <TableHeader>
      <TableRow>{tcols}</TableRow>
    </TableHeader>
    <TableBody>{trows}</TableBody>
  </Table>
))`
  tbody {
    tr:first-child {
      td {
        padding-top: 6px;
      }
    }

    .child-row {
      td {
        padding-left: 24px;
      }
    }

    .bg {
      background: ${({ theme }) =>
        theme.dark
          ? theme.table.zebra.dark.even.main
          : theme.table.zebra.light.even.main};
    }
  }

  th > div > span,
  td > div > span {
    max-width: 25em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .clickableRow:hover {
    background: ${({ theme }) => {
      const { light, dark } = theme.global.colors['background-contrast'];
      return theme.dark ? dark : light;
    }};
  }
  
  td:hover .action-buttons button {
    background: transparent;
  }

  td .action-buttons button:hover {
    background: ${({ theme }) => {
      const { light, dark } = theme.global.colors['background-contrast'];
      return theme.dark ? dark : light;
    }};
  }

  button,
  label,
  input + div {
    border-radius: 4px;
  }

  .child-row {
    td:first-child {
      padding-left: 28px;
    }
  }
`;

const FormInputTable = ({
  col,
  ds,
  mode,
  value,
  parentView,
  meta,
  form,
  className,
  onChange2,
  notifyInputTableChangeHandlers,
  validationResult,
  extraProps = {},
}) => {
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});

  const clonedStaticDataSources = useMemo(() => meta.cloneToStaticDataSources(), [meta]);

  const getMultiOptions = (items) => {
    const options = [];
    if (!isArrayLike(items)) {
      parentView.debug.throw(
        'getMultiOptions() requires an items array, got: ',
        items,
      );
    }
    for (const item of items) {
      options.push({
        value: item.id,
        label: item.name,
        disabled: item.disabled,
        clearableValue: !item.required,
      });
    }
    return options;
  };

  const modals = [];

  if (col.autoFocus) {
    extraProps = {
      ...extraProps,
      autoFocus: true,
    };
  }

  const inputStyle = { style: {} };
  if (col.width) {
    inputStyle.style.minWidth = col.width;
    inputStyle.style.maxWidth = col.width;
  }
  if (col.minWidth) {
    inputStyle.style.minWidth = col.minWidth;
  }
  if (col.maxWidth) {
    inputStyle.style.maxWidth = col.maxWidth;
  }

  const tcols = [];
  const trows = [];

  for (const dsCol of ds.data.cols) {
    if (isFunction(dsCol.fVisible) && !dsCol.fVisible()) {
      continue;
    }

    const thProps = {};
    if (dsCol.minCellWidth) {
      thProps.style = { minWidth: dsCol.minCellWidth };
    }
    tcols.push(
      <TableCell scope='col' {...thProps} key={tcols.length}>
        <Box pad={{ horizontal: 'small' }}>{dsCol.displayName}</Box>
      </TableCell>,
    );
  }

  // add row actions at the end
  if (!col.isReadOnly) {
    if (
      col.IsDeleteEnabled() ||
      col.IsResetEnabled() ||
      col.IsEditEnabled() ||
      col.IsUpDownEnabled()
    ) {
      tcols.push(<TableCell scope='col' key={tcols.length} />);
    }
  }

  let i = 0;
  for (const dsRow of ds.data.rows) {
    const row = i;
    const rowValue = value[i];
    const rawRowData = ds.data.rawrows[row];
    const cells = [];
    let children = [];

    for (const dsCol of ds.data.cols) {
      if (isFunction(dsCol.fVisible) && !dsCol.fVisible()) {
        continue;
      }
      const cellKeyPath = `${col.keyPath}[${i}].${dsCol.keyPath}`;
      let cellData = dsRow[dsCol.keyPath];
      // let cellValidationResult = dsCol.IsValid(cellData)
      // let cellValidationState = cellValidationResult.ok ? null : 'error'
      let cell = null;
      const readOnly = col.isReadOnly || dsCol.isReadOnly;

      let cellValue;
      if (rowValue !== undefined) {
        cellValue = getPath(rowValue, dsCol.keyPath);
      }

      switch (dsCol.type) {
        case mb.FieldTypeLabel:
        case mb.FieldTypeText:
        case mb.FieldTypeTextArea:
          if (isArray(cellData)) {
            cellData = cellData.join(', ');
          }
          if (dsCol.viewModalItemLink) {
            const modal = new ModalControl(
              parentView,
              col.keyPath,
              `view-row-${row}`,
            );

            modals.push(
              <Modal
                key={modal.key}
                show={modal.visible()}
                onHide={modal.hide}
                autoFocus
                size={col.editDialogSize}
              >
                <Modal.Header onDismiss={modal.hide}>
                  <Modal.Title>{col.displayName}</Modal.Title>
                </Modal.Header>
                <Modal.Body pad='none'>
                  <MetaForm
                    readOnly
                    meta={col.editDialogMeta}
                    className={`${className}::Modal::View`}
                    mode={mode}
                    parentView={parentView}
                    parentMeta={col.mb}
                    dataSources={clonedStaticDataSources}
                    formData={rawRowData}
                    cancelLabel='Back'
                    onCancel={modal.hide}
                    pad='medium'
                  />
                </Modal.Body>
              </Modal>,
            );
            cell = (
              <Link key={cellKeyPath} onClick={modal.show}>
                {cellData}
              </Link>
            );
          } else {
            cell = (
              <Text key={cellKeyPath} color='text-strong'>
                {cellData}
              </Text>
            );
          }

          break;

        case mb.FieldTypePassword:
          cell = <Text key={cellKeyPath}>*****</Text>;
          break;

        case mb.FieldTypeDropDown:
          if (cellData === undefined) {
            parentView.debug.error(
              `InputTable cellData missing for: ${dsCol.keyPath}`,
              ds,
            );
            throw new Error(
              `InputTable cellData missing for: ${dsCol.keyPath}`,
            );
          }

          if (readOnly) {
            cell = <LinksCell col={dsCol} ids={[cellValue]} data={cellData} />;
            break;
          }

          // this.debug.debug('InputTable:DropDown:render():' + cellKeyPath, cellValue, cellData)
          // otherwise
          // biome-ignore lint/correctness/noSwitchDeclarations: <explanation>
          const cellOptions = getMultiOptions(cellData);
          const onSelectChange = (selection) => {
            let value = '';
            if (selection && selection.value) {
              value = isNil(selection.value.value)
                ? undefined
                : selection.value.value; // undefined values are unset from the form
            }
            const oldVal = getPath(form, cellKeyPath);
            // standard change handler for this field is routed via cellKeyPath
            onChange2(cellKeyPath, value);
            // side-effect change handlers for input tables are multiplexed and routed
            // by the input table field at col.keyPath using row, dsCol.keyPath and
            // rawRowData has the original values
            notifyInputTableChangeHandlers(
              col.keyPath,
              dsCol.keyPath,
              row,
              rawRowData,
              value,
              oldVal,
            );
          };
          const placeholder = isEmpty(cellData)
            ? dsCol.placeholderIfEmpty
            : dsCol.placeholder;

          // TODO clearable+search and check placeholder
          cell = dsCol.isClearable ? (
            <SearchableSelect
              value={getMultiValue(cellOptions, cellValue)}
              placeholder={placeholder}
              options={cellOptions}
              onChange={onSelectChange}
              {...extraProps}
            />
          ) : (
            <Select
              value={getMultiValue(cellOptions, cellValue)}
              placeholder={placeholder}
              options={cellOptions}
              labelKey='label'
              valueKey='value'
              disabledKey='disabled'
              onChange={onSelectChange}
              {...extraProps}
            />
          );
          break;

        case mb.FieldTypeMultiSelect:
          if (cellData === undefined) {
            parentView.debug.error(
              `InputTable cellData missing for: ${dsCol.keyPath}`,
              ds,
            );
            throw new Error(
              `InputTable cellData missing for: ${dsCol.keyPath}`,
            );
          }
          let ids = [];
          parentView.debug.log('FieldTypeMultiSelect', ds, col, ids, ds.data);
          if (isArray(cellValue)) {
            ids = cellValue;
            cellValue = cellValue.join(','); // multiselect is an list of csv
          } else {
            cellValue = '';
          }

          // no data
          if (ds === undefined) {
            cell = <Text>No Data</Text>;
            break;
          }

          cell = <LinksCell col={dsCol} ids={ids} data={cellData} />;
          break;

        case mb.FieldTypeCustom:
          const columnNames = ds.data.cols.map(({ columnName }) => columnName);

          children =
            cellData?.map(({ __uniqueid, ...data }, i) => (
              <Fragment key={i}>
                {columnNames.map((column, j) => (
                  <TableCell key={j}>
                    <Text>{data[column]}</Text>
                  </TableCell>
                ))}
                <TableCell colSpan='2' />
              </Fragment>
            )) || null;

          break;

        default:
          throw new Error(`Unknown input table col type:${dsCol.type}`);
      }

      cells.push(
        <TableCell
        key={cells.length}
          style={{
            textAlign: dsCol.textAlign,
            paddingRight: dsCol.textAlign === 'right' ? '8px' : undefined,
          }}
        >
          {cell}
        </TableCell>,
      );
    }

    if (!col.isReadOnly) {
      const rowActions = [];
      const rack = col.mb.dataSources.find(
        ({ url }) => url === makeRestItemUrl(URL_RACKS),
      )?.data;
      const isUnmanaged = rack?.unmanaged_switch;

      if (col.IsEditEnabled(rawRowData) && isUnmanaged !== true) {
        const modal = new ModalControl(
          parentView,
          col.keyPath,
          `edit-row-${row}`,
        );

        const modalOk = (nextData, activeTab, view) => {
          parentView.debug.debug('modalOk:fireEdit', nextData, form);
          // rawRowData is the original value, before the edit
          if (col.FireOnEdit(nextData, rawRowData, modal, view) !== false) {
            modal.hide();
          }
          parentView.debug.debug('modalOk:fireEdit done', nextData, form);
        };

        rowActions.push(
          <IconButton
            key='edit'
            icon={<Edit color='brand' />}
            onClick={modal.show}
            tip='Edit'
          />,
        );
        modals.push(
          <Modal
            key={modal.key}
            show={modal.visible()}
            autoFocus
            size={col.editDialogSize}
          >
            <Modal.Header>
              <Modal.Title>Edit {col.displayName}</Modal.Title>
            </Modal.Header>
            <Modal.Body pad='none'>
              <MetaForm
                meta={col.editDialogMeta}
                className={`${className}::Modal::Edit`}
                mode={mode}
                parentView={parentView}
                parentMeta={col.mb}
                dataSources={clonedStaticDataSources}
                formData={rawRowData}
                submitLabel='OK' // here
                onSubmit={modalOk}
                cancelLabel='Cancel'
                onCancel={modal.hide}
                pad='medium'
              />
            </Modal.Body>
          </Modal>,
        );
      }

      if (col.IsDeleteEnabled(rawRowData)) {
        rowActions.push(
          <IconButton
            key='delete'
            plain
            onClick={() => {
              parentView.debug.debug('FireOnDelete handler', rawRowData);
              col.FireOnDelete(rawRowData);
            }}
            icon={<Trash color='brand' />}
            tip='Delete'
          />,
        );
      }

      if (col.IsResetEnabled()) {
        rowActions.push(
          <IconButton
            key='reset'
            plain
            onClick={() => {
              parentView.debug.debug('FireOnReset handler', rawRowData);
              col.FireOnReset(rawRowData);
            }}
            icon={<Refresh color='brand' />}
            tip='Reset'
          />,
        );
      }

      if (col.IsUpDownEnabled()) {
        rowActions.push(
          <IconButton
            key='up'
            plain
            onClick={() => {
              parentView.debug.debug('FireOnUp handler', rawRowData);
              col.FireOnUpDown('up', rawRowData);
            }}
            icon={<Up color='brand' />}
            tip='Move Up'
          />,
        );
        rowActions.push(
          <IconButton
            key='down'
            plain
            onClick={() => {
              parentView.debug.debug('FireOnDown handler', rawRowData);
              col.FireOnUpDown('down', rawRowData);
            }}
            icon={<Down color='brand' />}
            tip='Move Down'
          />,
        );
      }

      if (rowActions.length > 0) {
        cells.push(
          <TableCell key={cells.length}>
            <Box direction='row' className='action-buttons' gap='xxsmall'>
              {rowActions}
            </Box>
          </TableCell>,
        );
      }
    }

    const zebraRow = i % 2 ? 'bg' : '';

    const clickableRow = col.isReadOnly ? 'clickableRow' : '';

    const rowClick = (index) => () => {
      if (ds.data.rows[index]) {
        setRowData(ds.data.rawrows[index]);
        setOpen(true);
      }
    };

    trows.push(
      <TableRow
        className={`${zebraRow} ${clickableRow}`}
        key={trows.length}
        onClick={
          typeof col.onClickRow === 'function' ? rowClick(i) : col.onClickRow
        }
      >
        {cells}
      </TableRow>,
    );

    if (children) {
      children.forEach((childRow) =>
        trows.push(
          <TableRow className={`child-row ${zebraRow}`} key={trows.length}>
            {childRow}
          </TableRow>,
        ),
      );
    }

    i++;
  }

  const table = (
    <Box key={col.keyPath} gap='medium'>
      <Box gap='xsmall'>
        {col.showHeading ? (
          <Heading size='22px'>{col.displayName}</Heading>
        ) : null}
        {col.helperText ? <Text>{col.helperText}</Text> : null}
      </Box>
      <StyledTable col={col} tcols={tcols} trows={trows} />
    </Box>
  );

  const viewModel = [];
  if (col.isReadOnly) {
    viewModel.push(
      <Modal
        key={col.keyPath}
        show={open}
        onHide={() => setOpen(false)}
        autoFocus
        size={col.editDialogSize}
      >
        <Modal.Header onDismiss={() => setOpen(false)}>
          <Modal.Title>{col.displayName}</Modal.Title>
        </Modal.Header>
        <Modal.Body pad='none'>
          <MetaForm
            readOnly
            meta={col.editDialogMeta}
            className={`${className}::Modal::View`}
            mode={mode}
            parentView={parentView}
            parentMeta={col.mb}
            dataSources={clonedStaticDataSources}
            formData={rowData}
            cancelLabel='Close'
            onCancel={() => setOpen(false)}
            pad='medium'
          />
        </Modal.Body>
      </Modal>,
    );
  }

  if (!col.IsAddEnabled()) {
    return (
      <div key={col.keyPath} style={{ gridColumn: '1 / span 2' }}>
        {table}
        {modals}
        {viewModel}
      </div>
    );
  }

  // otherwise, add enabled
  const modal = new ModalControl(parentView, col.keyPath, 'add');
  parentView.debug.isDebug && parentView.debug.log('modalInputs:', ds.data.cols);
  // let modalFormInputs = this.getFormInputs(ds.data.cols)

  const modalOk = (data, activeTab, view) => {
    parentView.debug.debug('modalOk:fireAdd', ds.data, form);
    if (col.FireOnAdd(data, modal, view) !== false) {
      modal.hide();
    }
    parentView.debug.debug('modalOk:fireAdd done', ds.data, form);
  };

  const content = [];
  content.push(table);
  if (!col.isReadOnly) {
    content.push(
      <Box
        key='add-button'
        margin={{ horizontal: 'xsmall', vertical: 'small' }}
      >
        <div>
          <IconButton
            key='add'
            onClick={modal.show}
            icon={<Add color='brand' />}
            tip='Add'
          />
        </div>
      </Box>,
    );
  }

  modals.push(
    <Modal
      key={modal.key}
      show={modal.visible()}
      autoFocus
      size={col.addDialogSize}
    >
      <Modal.Header>
        <Modal.Title>Add {col.displayName}</Modal.Title>
      </Modal.Header>
      <Modal.Body pad='none'>
        <MetaForm
          meta={col.addDialogMeta}
          className={`${className}::Modal::Add`}
          mode={mode}
          parentView={parentView}
          parentMeta={col.mb}
          dataSources={clonedStaticDataSources}
          submitLabel='OK'
          onSubmit={modalOk}
          cancelLabel='Cancel'
          onCancel={modal.hide}
          pad='medium'
        />
      </Modal.Body>
    </Modal>,
  );

  const helpBlock = [];
  if (!isEmpty(validationResult.msg)) {
    helpBlock.push(
      <HelpBlock key={`help${helpBlock.length}`}>
        {validationResult.msg}
      </HelpBlock>,
    );
  }
  helpBlock.push(
    ...validationResult.cellResults.map((r, i) => (
      <HelpBlock key={`help${helpBlock.length}-${i}`}>{r.msg}</HelpBlock>
    )),
  );

  return (
    <div key={col.keyPath} style={{ gridColumn: '1 / span 2' }}>
      {helpBlock}
      {content}
      {modals}
      {viewModel}
    </div>
  );
};

export default FormInputTable;