// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { useParams } from 'react-router-dom';

import { URL_MACHINES } from '../../routes/consts';
import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { useGetMachines } from '../../services/machines';
import { NAV_COMPUTE } from '../../components/HybridNav/consts.js';

const MachineListView = () => {
  const { id } = useParams();

  return (
    <ListPageWithNav
      navSection={NAV_COMPUTE}
      actions={{
        allowPause: true,
        allowAddTags: true,
        allowRemoveTags: true,
        poll: true,
      }}
      parentId={id}
      path={URL_MACHINES}
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      title='Machines'
      useGetData={useGetMachines}
      limitRecords={true}
    />
  );
};

export default MachineListView;
