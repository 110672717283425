// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import { isEmpty } from '../../utils/lodash';
import * as c from '../../routes/consts';
import MetaBuilder, {
  SMALL,
  LARGE,
} from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import { ProjectMemberListView } from './members';
import { netmaskIPv4 } from '../../data/network';
import auth from '../../lib/auth';
import InstanceTypes from './InstanceTypes';
import { toDateTime } from '../../lib/formatters';
import { FormPermittedServices } from '../../containers/generic/MetaForm/FormPermittedServices';
import { LABEL_PROJECTS } from '../../components/HybridNav/consts.js';

const BASE_URL = c.URL_PROJECTS;

function metaList(props) {
  const mb = new MetaBuilder(props);
  const isHoster = auth.activeScope === 'hoster';
  const organizations = isHoster
    ? new MetaBuilder(props).newDataSource(c.URL_ORGANIZATIONS).Fetch()
    : new MetaBuilder(props).newStaticDataSource([]);

  mb.addRowClick((navigate) => ({ datum: { id } }) => {
    navigate(c.setId(c.makeSecItemUrl(BASE_URL), id));
  });

  //-----------------------------------------
  mb.addSection('Basic info').Expanded().MaxWidth(LARGE);
  //-----------------------------------------

  mb.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(BASE_URL), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input()
    .Required();

  mb.addColumn('id', 'ID');
  mb.addColumn('organization_id', 'Organization')
    .Custom(ColumnLink, {
      info: (rowData) => {
        if (isHoster) {
          const url = c.setId(
            c.makeSecItemUrl(c.URL_ORGANIZATIONS),
            rowData.organization_id,
          );

          const data = organizations.Data();

          if (!isEmpty(data)) {
            const org = data.find((r) => r.id === rowData.organization_id);

            return { url, name: org ? org.name : rowData.organization_id };
          }

          return { url: '#', name: '' };
        }

        return { url: '#', name: '' };
      },
    })
    .Default();

  mb.addColumn('created', 'Created')
    .FieldXform((created) => (created ? toDateTime(created) : '--'))
    .Default();
  mb.addColumn('modified', 'Modified')
    .FieldXform((modified) => (modified ? toDateTime(modified) : '--'))
    .Default();

  //-----------------------------------------
  mb.addSection('Profile').Expanded().MaxWidth(LARGE);
  //-----------------------------------------

  mb.addColumn('profile.team_name', 'Project name').Input();
  mb.addColumn('profile.team_desc', 'Project description').Input();
  mb.addColumn('profile.company', 'Company').Input();
  mb.addColumn('profile.address', 'Address').Input();
  mb.addColumn('profile.email', 'Email').Input();
  mb.addColumn('profile.phone_number', 'Phone').Input();

  //-----------------------------------------
  mb.addSection('Private IP pool')
    .Expanded()
    .MaxWidth(LARGE)
    .OverviewText(
      'IP address pool from which private subnets will be allocated',
    );

  mb.addColumn('private_ip_pool_factory.base', 'Base address').Input();

  mb.addColumn('private_ip_pool_factory.base_cidr', 'Pool size').Input();

  mb.addColumn('private_ip_pool_factory.ip_pool_cidr', 'Sub-Pool size').Input();

  if (mb.readOnly) {
    //-----------------------------------------
    mb.addSection('Restrict instance types')
      .Expanded()
      .MaxWidth(LARGE)
      .OverviewText(
        'Instance types will be restricted to the types specified here. Zero value for a specified instance type indicates no limit. If nothing is specified, all instance types can be used with no limits.',
      );

    mb.addColumn('limits.machine_sizes', 'Instance types').Custom(
      InstanceTypes,
    );

    //-----------------------------------------
    mb.addSection('Restrict service images')
      .Expanded()
      .MaxWidth(LARGE)
      .OverviewText(
        'Images will be restricted to the ones specified here. If nothing is specified, all images will be allowed.',
      );

    mb.addColumn('permitted_services', 'Permitted services')
      .Input()
      .Custom(FormPermittedServices);
  }

  //-----------------------------------------
  mb.addSection('Limits')
    .Expanded()
    .MaxWidth(LARGE)
    .OverviewText(
      `Limits on what the project can create and manage within the portal.
      Only editable by hoster. Zero values indicate no limit.`,
    );
  //-----------------------------------------

  // project limits
  mb.addColumn('limits.hosts', 'Hosts')
    .Default()
    .Input('10')
    .Required()
    .RegEx('^[0-9]+$');

  mb.addColumn('limits.private_networks', 'Private networks')
    .Default()
    .Input('10')
    .Required()
    .RegEx('^[0-9]+$');

  mb.addColumn('limits.volumes', 'Volumes')
    .Default()
    .Input('10')
    .Required()
    .RegEx('^[0-9]+$');

  mb.addColumn('limits.volume_capacity', 'Storage capacity (TiB)')
    .Default()
    .Input('10')
    .Required()
    .RegEx('^[0-9]+$');

  mb.addColumn('replication_enabled', 'High availability storage')
    .Input()
    .CheckBox(false)
    .Help(
      'Enabling High availability storage will apply to all volumes within this project.',
    );

  //-----------------------------------------
  mb.addSection().Table(ProjectMemberListView).MaxWidth(1200).Expanded();
  //-----------------------------------------

  return mb;
}

function metaCreate(props) {
  const mb = new MetaBuilder(props);

  const isHoster = auth.activeScope === 'hoster';
  const dsOrg = isHoster
    ? mb.newDataSource(c.URL_ORGANIZATIONS)
    : mb.newStaticDataSource([]);

  const id = mb.view.prop('itemId', false);

  if (id !== undefined) {
    mb.newDataSource(BASE_URL)
      .Item(id)
      .OnLoad((json) => {
        // debug.debug('got project:', json)
        mb.view.initForm(json.data);
      });
  }

  //-----------------------------------------
  mb.addSection('Info').MaxWidth(LARGE);
  //-----------------------------------------

  mb.addColumn('name', 'Name').Default().Input().Required();

  // project profile
  mb.addColumn('profile.team_name', 'Team name')
    .Input()
    .Required()
    .MaxWidth(SMALL);
  mb.addColumn('profile.team_desc', 'Team description').Input();
  mb.addColumn('profile.company', 'Company').Input();
  mb.addColumn('profile.address', 'Address').Input();
  mb.addColumn('profile.email', 'Email').Input();
  mb.addColumn('profile.phone_number', 'Phone').Input();
  mb.addColumn('organization_id', 'Organization')
    .Input()
    .DropDown()
    .ReadOnly()
    .DataXform(dsOrg, (json) => {
      const data = [
        { id: '', name: 'Organization can only be set via project REST API' },
      ];
      return data.concat(json.map((o) => ({ id: o.id, name: o.name })));
    });

  //-----------------------------------------
  if (props.mode === 'edit') {
    mb.addSection('Private IP pool')
      .Expanded()
      .MaxWidth(LARGE)
      .OverviewText(
        'IP address pool from which private subnets will be allocated',
      );

    mb.addColumn('private_ip_pool_factory.base', 'Base address').Input();

    mb.addColumn('private_ip_pool_factory.base_cidr', 'Pool size')
      .Input()
      .Input('/8')
      .DropDown('/8')
      .DataXform(mb.newStaticDataSource(netmaskIPv4), (json) =>
        json.map((t) => ({ id: t, name: t })),
      )
      .RequiredSometimes(() => mb.view.formDefaultTo('base') !== '');

    mb.addColumn('private_ip_pool_factory.ip_pool_cidr', 'Sub-Pool size')
      .Input()
      .DropDown('/16')
      .DataXform(mb.newStaticDataSource(netmaskIPv4), (json) =>
        json.map((t) => ({ id: t, name: t })),
      )
      .RequiredSometimes(() => mb.view.formDefaultTo('base') !== '');
  }

  // project limits
  if (isHoster || (!isHoster && props.mode !== 'edit')) {
    //-----------------------------------------
    mb.addSection('Restrict Instance types')
      .Expanded()
      .OverviewText(
        `Instance types will be restricted to the types specified here.
        All unspecified types will be disallowed. Zero values indicate
        no limit.`,
      );

    mb.addColumn('type', 'Host type').Input().Custom(InstanceTypes);

    //-----------------------------------------
    mb.addSection('Restrict service images')
      .Expanded()
      .OverviewText(
        `Images will be restricted to the ones specified here.
        All unspecified images will be disallowed.`,
      );

    mb.addColumn('permitted_services', 'Permitted services')
      .Input()
      .Custom(FormPermittedServices);

    //-----------------------------------------
    mb.addSection('Limits')
      .Expanded()
      .MaxWidth(LARGE)
      .OverviewText(
        `Limits on what the project can create and manage within the portal.
        Only editable by hoster.  Zero values indicate no limit.`,
      );

    mb.addColumn('limits.hosts', 'Hosts')
      .Default()
      .Input('10')
      .Required()
      .RegEx('^[0-9]+$')
      .ReadOnly(!isHoster);

    mb.addColumn('limits.private_networks', 'Private networks')
      .Default()
      .Input('10')
      .Required()
      .RegEx('^[0-9]+$')
      .ReadOnly(!isHoster);

    mb.addColumn('limits.volumes', 'Volumes')
      .Default()
      .Input('10')
      .Required()
      .RegEx('^[0-9]+$')
      .ReadOnly(!isHoster);

    mb.addColumn('limits.volume_capacity', 'Storage capacity (TiB)')
      .Default()
      .Input('10')
      .Required()
      .RegEx('^[0-9]+$')
      .ReadOnly(!isHoster);

    mb.addColumn('replication_enabled', 'High availability storage')
      .Input()
      .CheckBox(false)
      .ReadOnly(props.mode === 'edit')
      .Help(
        'Enabling High availability storage will apply to all volumes within this project.',
      );
  }

  return mb;
}

const settings = {
  // used to filter create/trash icons from the view;
  // using the defined roledef permissions mappings to this auth URL entry
  authUrl: BASE_URL,
  // homepage for this list view (e.g. /s/hosters/:pid/) is where
  // this view is located; for return from create / item views
  homeUrl: c.makeSecUrl(BASE_URL),
  homeLabel: LABEL_PROJECTS,
  // base url to be used for creating all associated URLS for
  // this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  baseUrl: BASE_URL,
};

export { default as ProjectListView } from './ProjectListView';

export const ProjectItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metaList(props),
  allowEdit: true,
  title: 'Project',
});

export const ProjectItemViewNoEdit = ItemViewContainer({
  ...settings,
  meta: (props) => metaList(props),
  allowEdit: false,
  title: 'Project',
});

export const ProjectCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create project',
});

export const ProjectEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Edit project',
});
