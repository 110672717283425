// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQueryParams() {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  return params;
}
