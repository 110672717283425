// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { renderMutableLink } from '../services/helper';
import { Edit } from 'grommet-icons';

export const editColumn = ({ base, role, condition }) => {
  const displayIt = condition ?? role?.isScopeHoster?.();

  return !role?.isRoleHosterViewer?.() && displayIt
    ? [
        {
          header: 'Edit',
          isDefault: true,
          property: 'edit',
          render: renderMutableLink({
            base,
            icon: <Edit color='brand' />,
            isEdit: true,
          }),
        },
      ]
    : [];
};
