// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { URL_VOLUME_FLAVOR, URL_VOLUME_FLAVORS } from '../routes/consts';
import { renderLink } from './helper';
import { getVolumeFlavors, volumeFlavorMapper } from './volumes';
import { toDateTime } from '../lib/formatters';
import { editColumn } from '../utils/editColumn';
import { useMembershipContext } from '../utils/context/membershipContext';

export const columns = (role) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_VOLUME_FLAVOR,
    }),
  },
  {
    header: 'Description',
    property: 'description',
    isDefault: true,
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Project visible',
    property: 'project_use',
    isDefault: true,
    render: ({ project_use: use }) => (use ? 'Yes' : 'No'),
  },
  {
    header: 'Hoster visible',
    property: 'hoster_use',
    isDefault: true,
    render: ({ hoster_use: use }) => (use ? 'Yes' : 'No'),
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  ...editColumn({ base: URL_VOLUME_FLAVOR, role }),
];

export const useGetVolumeFlavors = () => {
  const { role } = useMembershipContext();
  const { data, error, isLoading, isError, refetch } = useQuery({
    queryKey: URL_VOLUME_FLAVORS,
    select: (data) => data.map(volumeFlavorMapper),
    queryFn: getVolumeFlavors,
  });

  return {
    columns: columns(role).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
    data,
    error,
    isLoading,
    isError,
    refetch,
  };
};
