// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getRole } from '../../services/membership';
import auth from '../../lib/auth';
import { enhanceRole } from '../rootUtils';

export const MembershipContext = createContext();

export const MembershipProvider = ({
  activeMembership = {},
  children,
  memberships = [],
  role = {},
}) => {
  const [context, setContext] = useState({});

  // The unit test engine is not able to handle the sync context
  // without a break in the eventLoop.
  // Here it wraps the setContext in a setTimeout to allow the eventLoop to break.
  // It avoids a recursive loop in the unit test.
  const setContextWrap = useCallback((newContext) => {
    setTimeout(() => {
      setContext(newContext);
    }, 0);
  }, []);

  useEffect(() => {
    setContextWrap((prevContext) => ({
      ...prevContext,
      activeMembership,
    }));
  }, [activeMembership, setContextWrap]);

  useEffect(() => {
    setContextWrap((prevContext) => ({
      ...prevContext,
      memberships,
    }));
  }, [memberships, setContextWrap]);

  useEffect(() => {
    setContextWrap((prevContext) => ({
      ...prevContext,
      role,
    }));
  }, [role, setContextWrap]);

  useEffect(() => {
    if (context.activeMembership?.id) {
      getRole().then((roleDef) => {
        setContextWrap((prevContext) => ({
          ...prevContext,
          role: enhanceRole(roleDef),
        }));
      });
    }
  }, [context.activeMembership, setContextWrap]);

  useEffect(() => {
    auth.setActiveRole(context.role);
    auth.setActiveScope(context.role?.scope);
  }, [context.role]);

  return (
    <MembershipContext.Provider
      value={{
        ...context,
        setContext: setContextWrap,
      }}
    >
      {children}
    </MembershipContext.Provider>
  );
};

export const useMembershipContext = () => {
  const context = useContext(MembershipContext);

  if (!context) {
    throw new Error('MembershipContext must be used within a AppProvider');
  }

  return context;
};
