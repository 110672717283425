// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import numeral from 'numeral';
import moment from 'moment';
import time from './time.js';

const toMoney = (num) => numeral(num).format('$0,0.00');

const toDateTime = (timestamp, is24HourFormat) => {
  if (!timestamp) return '';
  const mom = moment(timestamp); // , 'YYYY-MM-DD HH:MM:SS.SSSSSSSSS')
  return is24HourFormat
    ? mom.format('YYYY-MM-DD,  HH:mm:ss.SSS') // 24-hour format
    : mom.format('YYYY/MM/DD,  hh:mm:ss A'); // 12-hour format with AM/PM
};

const toTitleCase = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );

const elapsed = (dateIn) => {
  const now = time().portal();
  const start = new Date(dateIn);

  let secs = Math.trunc((now - start) / 1000);
  // biome-ignore lint/suspicious/noGlobalIsNan: <explanation>
  if (isNaN(secs)) {
    secs = 0;
  }

  const days = Math.floor(secs / 86400);
  const hours = Math.floor((secs - days * 86400) / 3600);
  let minutes = Math.floor((secs - hours * 3600 - days * 86400) / 60);
  let seconds = secs - hours * 3600 - minutes * 60 - days * 86400;

  switch (true) {
    case secs < 60:
      return `${seconds}s`;
    case secs < 3600:
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes}:${seconds}m`;
    case secs < 86400:
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      return `${hours}:${minutes}h`;
    default:
      return `${days}d`;
  }
};

export { toMoney, toDateTime, toTitleCase, elapsed };
