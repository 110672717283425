// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { makeRestUrl, URL_RACKS } from '../routes/consts';
import { renderLink, Status } from './helper';
import { editColumn } from '../utils/editColumn';
import { useMembershipContext } from '../utils/context/membershipContext';

export const columns = (role) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_RACKS,
    }),
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'description',
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Comm status',
    isDefault: true,
    property: 'portal_comm_okay',
    render: ({ portal_comm_okay: okay }) => {
      const content = okay ? 'OK' : 'NC';
      const status = okay ? '' : 'status-warning';
      const tooltip = okay ? '' : 'Management connection lost to this host';

      return <Status content={content} status={status} tooltip={tooltip} />;
    },
  },
  {
    header: 'Comm status reason',
    property: 'portal_comm_reason',
  },
  {
    header: 'Type',
    isDefault: true,
    property: 'type',
  },
  {
    header: 'Store ID',
    isDefault: true,
    property: 'store_id',
  },
  ...editColumn({
    base: URL_RACKS,
    condition: !role?.isRoleAnyViewer?.(),
    role,
  }),
];

export const getRacks = async (apiParams = {}) => {
  const response = await get(makeRestUrl(URL_RACKS), apiParams);
  return response.json();
};

export const useGetRacks = ({ apiParams }) => {
  const { role } = useMembershipContext();
  const queryResult = useQuery([URL_RACKS, apiParams], () =>
    getRacks(apiParams),
  );

  return {
    ...queryResult,
    columns: columns(role).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
