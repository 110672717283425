// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import * as c from '../../routes/consts.js';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder, {
  ValidationResult,
} from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import { VERSION_MASK } from '../../data/regex';
import { urlIcon, urlText } from '../../utils';
import {
  SERVICE_IMAGE_SECURE_URL,
  SERVICE_IMAGE_URL,
  SERVICE_IMAGE_URL_MSG,
  URL_MAX_LENGTH,
} from '../../data/regex';
import { LABEL_FMBASELINES } from '../../components/HybridNav/consts.js';
import { fwBaselinesMapper } from '../../services/fwBaselines.js';

const FWBASELINE_SHA_256 = 'sha256sum';
const FWBASELINE_SHA_512 = 'sha512sum';
const FWBASELINE_HASHES = ['none', FWBASELINE_SHA_256, FWBASELINE_SHA_512];
const DS_FWBASELINE = 'fwbaseline';

const debug = debugLogger('FWBaselines settings', log.LOG_LEVEL_WARN);

const EXTERNAL_NAME = c.URL_FWBASELINES;

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_FMBASELINES,
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
};

const isPositive = (message) => (int) =>
  int >= 0
    ? new ValidationResult(true, '')
    : new ValidationResult(false, message);

function metaCreate(props) {
  const m = new MetaBuilder(props);

  const id = m.view.prop('itemId', false);
  let ds;

  if (id === undefined) {
    ds = m.newStaticDataSource({}, DS_FWBASELINE);
  } else {
    ds = m
      .newDataSource(c.URL_FWBASELINES, DS_FWBASELINE)
      .Item(id)
      .useMapper(fwBaselinesMapper)
      .OnLoad((json) => {
        debug.debug('got service:', json);
        m.view.initForm(json.data);
      });
  }

  m.addSection('1').NoChrome().MaxWidth(mb.LARGE);

  m.addColumn('name', 'Name').Input().Required();

  if (props.mode === 'view') {
    m.addColumn('id', 'ID');
  }

  m.addColumn('description', 'Description').Input();
  m.addColumn('version', 'Version').Input().Mask(VERSION_MASK).Required();
  m.addColumn('manufacturer', 'Manufacturer').Input();
  m.addColumn('timeout', 'Timeout')
    .Input()
    .Info('Overall timeout for full firmware update operation in seconds')
    .Number()
    .Required()
    .CustomValidator(isPositive('Timeout must be a positive number'));

  m.addSection('Files')
    .OverviewText(
      'Files to be used for the firmware baseline. The files will be served via HTTP(S).',
    )
    .MaxWidth(mb.XLARGE);

  const fileTable = m
    .addInputTable('files', 'Files')
    .DataXform(ds, (json) => defaultTo(get(json, 'files', []), []));

  fileTable.AddDialogMeta(metaFile);
  fileTable.OnAdd((data) => {
    ds.Push('files', data);
    m.view.addFormValue('files', data);
  });
  fileTable.OnDelete((data) => {
    ds.Remove('files', data);
    m.view.removeFormValue('files', data);
  });
  fileTable.EditDialogMeta(metaFile);
  fileTable.OnEdit((nextData, oldData) => {
    ds.Update('files', oldData, nextData, true);
    m.view.updateFormValue('files', oldData, nextData, true);
  });

  fileTable.addField('path', 'File name').CellXform((rowData) => rowData.path);
  fileTable
    .addField('file_size', 'File size')
    .CellXform((rowData) => rowData.file_size);
  fileTable
    .addField('secure_url', 'Security')
    .CellXform((rowData) => urlIcon(rowData));
  fileTable
    .addField('display_url', 'URL')
    .CellXform((rowData) => urlText(rowData));

  return m;
}

function metaFile(props) {
  const m = new MetaBuilder(props);

  m.addColumn('path', 'Path').Input().Required();

  m.addColumn('file_size', 'File size').Number().Input().Required();

  m.addColumn('display_url', 'Display URL')
    .Input()
    .MaxLength(URL_MAX_LENGTH)
    .RegEx(SERVICE_IMAGE_URL, SERVICE_IMAGE_URL_MSG('firmware baseline'));

  // TODO: improve handling for secure_url default value vs new one
  //  - if secure_url gets focus, all is selected/replaced
  //  - use type=password to prevent secure_url value from being seen?
  m.addColumn('secure_url', 'Secure URL')
    .Input()
    .MaxLength(URL_MAX_LENGTH)
    .RegEx(
      SERVICE_IMAGE_SECURE_URL,
      SERVICE_IMAGE_URL_MSG('firmware baseline'),
    );

  m.addColumn('download_timeout', 'Download timeout')
    .Input(300)
    .Info('in seconds')
    .Required()
    .Number()
    .CustomValidator(isPositive('Timeout must be a positive number'));

  m.addColumn('algorithm', 'File hash algorithm')
    .Input(FWBASELINE_SHA_256)
    .DropDown()
    .DataXform(m.newStaticDataSource(FWBASELINE_HASHES), (json) =>
      json.map((t) => ({ id: t, name: t })),
    )
    .Width(mb.SMALL)
    .Required();

  const hasAlgorithm = () => m.view.formDefaultTo('algorithm', '') !== 'none';
  m.addColumn('signature', 'File signature')
    .Input()
    .RequiredSometimes(hasAlgorithm)
    .Visible(hasAlgorithm);

  return m;
}

export { default as FWBaselinesListView } from './FWBaselineListView';

export const FWBaselinesItemView = ItemViewContainer({
  ...settings,
  allowEdit: ({ immutable }) => !immutable,
  meta: (props) => metaCreate(props),
  title: 'FW baselines',
});

export const FWBaselinesCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create FW baseline',
});

export const FWBaselinesEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Edit FW baseline',
});
