// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQueries } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { makeRestUrl, URL_DASHBOARD, URL_MACHINESIZES } from '../routes/consts';
import { getDashboard } from './dashboard';
import { getDefaultQueriesResponse, renderLink } from './helper';
import { editColumn } from '../utils/editColumn';
import { useMembershipContext } from '../utils/context/membershipContext';

const getFromPool = (dashboard, name, key) => {
  const pools = dashboard?.machine_pool_summary?.machine_pools || [];
  const pool = pools.find(({ name: poolName }) => poolName === name);

  return pool?.[key] || '';
};

export const columns = (role) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_MACHINESIZES,
    }),
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'description',
  },
  {
    header: 'ID',
    isDefault: true,
    property: 'id',
  },
  {
    header: 'Weight',
    isDefault: true,
    property: 'weight',
  },
  {
    header: 'Available',
    isDefault: true,
    property: 'unallocated',
  },
  {
    header: 'Allocated',
    isDefault: true,
    property: 'allocated',
  },
  {
    header: 'Others',
    isDefault: true,
    property: 'others',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  ...(role?.isScopePortal?.()
    ? [
        {
          header: 'Published',
          isDefault: true,
          property: 'hoster_published',
          render: ({ hoster_id: id }) => (id === '*' ? 'yes' : 'no'),
        },
      ]
    : []),
  ...editColumn({ base: URL_MACHINESIZES, role }),
];

export const machineSizesMapper = (data) => ({
  ...data,
  immutable: !!data.auto_config?.synced_from_remote,
});

export const getMachineSizes = async () => {
  const url = makeRestUrl(URL_MACHINESIZES);
  const response = await get(url);

  return response.json();
};

export const useGetMachineSizes = ({ hosterId }) => {
  const { role } = useMembershipContext();
  const queryResults = useQueries([
    {
      queryKey: URL_MACHINESIZES,
      select: (data) => data.map(machineSizesMapper),
      queryFn: getMachineSizes,
    },
    {
      queryKey: URL_DASHBOARD,
      queryFn: getDashboard(hosterId),
      enabled: !!hosterId,
    },
  ]);

  const isLoaded = queryResults.every(
    ({ error, isLoading }) => !isLoading && !error,
  );

  if (isLoaded) {
    const [machineSizesQuery, dashboardQuery] = queryResults;
    const dashboard = dashboardQuery?.data || {};

    return {
      columns: columns(role)
        // adding columnName and displayName for backwards compatibility
        .map((column) => ({
          ...column,
          columnName: column.property,
          displayName: column.header,
        })),
      data:
        machineSizesQuery.data?.map((machineSize) => ({
          ...machineSize,
          allocated: getFromPool(dashboard, machineSize.name, 'allocated'),
          other: getFromPool(dashboard, machineSize.name, 'other'),
          unallocated: getFromPool(dashboard, machineSize.name, 'unallocated'),
        })) ?? [],
      isLoading: false,
      refetch: () => {
        machineSizesQuery.refetch();
        dashboardQuery.refetch();
      },
      error: null,
    };
  }

  return getDefaultQueriesResponse(queryResults);
};
