// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { toDateTime } from '../lib/formatters';
import { get } from '../lib/rest';
import { makeRestUrl, URL_HOSTERS } from '../routes/consts';
import { renderLink } from './helper';
import TruncateText from '../components/Griddle/TruncateText';
import { editColumn } from '../utils/editColumn';
import { useMembershipContext } from '../utils/context/membershipContext';

export const columns = (role) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_HOSTERS,
    }),
  },
  {
    header: 'Team name',
    isDefault: true,
    property: 'profile.team_name',
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'profile.team_desc',
  },
  {
    header: 'Company',
    property: 'profile.company',
  },
  {
    header: 'Address',
    property: 'profile.address',
  },
  {
    header: 'Email',
    isDefault: true,
    property: 'profile.email',
  },
  {
    header: 'Phone',
    isDefault: true,
    property: 'profile.phone_number',
  },
  {
    header: 'Created',
    isDefault: true,
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'ID',
    property: 'id',
    render: ({ id }) => <TruncateText content={id} />,
  },
  {
    header: 'How private IPs are allocated for projects',
    property: 'private_ip_pool_factory.unique_ip_ranges',
    render: ({ private_ip_pool_factory: factory }) => {
      const { unique_ip_ranges: isUniqueIpRanges } = factory || {};

      return isUniqueIpRanges
        ? 'Unique IP Ranges'
        : 'Re-use IP Ranges in Each Project';
    },
  },
  {
    header: 'Base network address',
    property: 'private_ip_pool_factory.base',
  },
  {
    header: 'Base pool size',
    property: 'private_ip_pool_factory.base_cidr',
  },
  {
    header: 'Sub-Pool size',
    property: 'private_ip_pool_factory.ip_pool_cidr',
  },
  {
    header: 'Pods',
    property: 'limits.pods',
  },
  {
    header: 'Racks',
    property: 'limits.racks',
  },
  {
    header: 'Switches/Rack',
    property: 'limits.switches_per_rack',
  },
  {
    header: 'Hosts',
    property: 'limits.hosts',
  },
  {
    header: 'Volumes',
    property: 'limits.volumes',
  },
  {
    header: 'Volume capacity (TiB)',
    property: 'limits.volume_capacity',
  },
  {
    header: 'Projects',
    property: 'limits.projects',
  },
  {
    header: 'Auto config',
    property: 'auto_config',
  },
  ...editColumn({ base: URL_HOSTERS, role }),
];

export const getHosters = async () => {
  const response = await get(makeRestUrl(URL_HOSTERS));

  return response.json();
};

export const useGetHosters = (scope) => {
  const queryResult = useQuery(URL_HOSTERS, getHosters, {
    enabled: scope === 'hosters',
  });

  return queryResult;
};

export const useGetHostersData = () => {
  const queryResult = useQuery(URL_HOSTERS, getHosters);
  const { role } = useMembershipContext();

  return {
    ...queryResult,
    columns: columns(role).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
    data:
      queryResult.data?.map((hoster) => {
        const { branch, mode, repo } = hoster?.auto_config ?? {};
        const config =
          branch && mode && repo ? `${branch} | ${mode} | ${repo}` : '';

        return {
          ...hoster,
          auto_config: config,
        };
      }) ?? [],
  };
};
