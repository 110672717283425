// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import isBoolean from 'lodash/isBoolean';
import isFunction from 'lodash/isFunction';

export const LOG_LEVEL_NONE = 0;
export const LOG_LEVEL_ERROR = 1;
export const LOG_LEVEL_WARN = 2;
export const LOG_LEVEL_INFO = 3;
export const LOG_LEVEL_DEBUG = 4;

export default function debugLogger(name, level = LOG_LEVEL_WARN) {
  const suppressLogs = process.env.REACT_APP_SUPPRESS_LOGS === 'true';
  if (suppressLogs) {
    // biome-ignore lint: <explanation>
    level = LOG_LEVEL_NONE;
  } else if (isBoolean(level)) {
    if (level) {
      // biome-ignore lint: <explanation>
      level = LOG_LEVEL_INFO;
    } else {
      // biome-ignore lint: <explanation>
      level = LOG_LEVEL_ERROR;
    }
  }

  let debug = (msg, ...objs) => {};
  let log = (msg, ...objs) => {};
  let warn = (msg, ...objs) => {};
  let error = (msg, ...objs) => {};
  let throwErr = (msg, ...objs) => {};

  if (level >= LOG_LEVEL_DEBUG) {
    debug = (msg, ...objs) => {
      console.log(`[${name}] DEBUG ${msg}`, ...objs);
    };
  }

  if (level >= LOG_LEVEL_INFO) {
    log = (msg, ...objs) => {
      console.log(`[${name}] ${msg}`, ...objs);
    };
  }

  if (level >= LOG_LEVEL_WARN) {
    warn = (msg, ...objs) => {
      const err = new Error();
      if (isFunction(console.warn)) {
        console.warn(`[${name}] ${msg}`, ...objs, `\n${err.stack}`);
      } else {
        console.log(`[${name}] WARN ${msg}`, ...objs, `\n${err.stack}`);
      }
    };
  }

  if (level >= LOG_LEVEL_ERROR) {
    error = (msg, ...objs) => {
      const err = new Error();
      if (isFunction(console.error)) {
        console.error(`[${name}] ${msg}`, ...objs, `\n${err.stack}`);
      } else {
        console.log(`[${name}] ERROR ${msg}`, ...objs, `\n${err.stack}`);
      }
    };
    throwErr = (msg, ...objs) => {
      const err = new Error(msg);
      if (isFunction(console.error)) {
        console.error(`[${name}] ${msg}`, ...objs, `\n${err.stack}`);
      } else {
        console.log(`[${name}] ERROR ${msg}`, ...objs, `\n${err.stack}`);
      }
      throw err;
    };
  }

  return {
    isDebug: level >= LOG_LEVEL_DEBUG,
    isLog: level >= LOG_LEVEL_INFO,
    isWarn: level >= LOG_LEVEL_WARN,
    isError: level >= LOG_LEVEL_ERROR,
    debug,
    log,
    warn,
    error,
    throw: throwErr,
  };
}
