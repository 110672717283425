// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import remove from 'lodash/remove';
import set from 'lodash/set';
import rest from './rest';
import { REFRESH_TIME } from '../config';
import debugLogger from './debug';
import * as c from '../routes/consts.js';
import * as log from './debug.js';
import qs from './querystring.js';
import { getPath, setPath } from './deep';
import { assignUniqueIds, getUniqueIdPredicate } from './uniqueids';

const debug = debugLogger('DS', log.LOG_LEVEL_WARN);

export class DataSource {
  constructor(url, name) {
    if (url === undefined) {
      debug.throw('URL is undefined.');
    }
    debug.debug('Datasource:', url, name);
    this.name = name;
    this.baseUrl = url;
    if (url.startsWith('/')) {
      this.url = url;
    } else {
      this.url = c.makeRestUrl(url);
    }

    this.data = null;
    this.status = -1; // legacy, use this.lastResult going forward
    this.errText = ''; // legacy, use this.lastResult going forward
    this.setLastResult(this.url, this.status, this.errText);
    this.queryStrings = new Map();

    this.onLoadListeners = [];
    this.internalOnLoadListeners = [];
    this.xforms = [];
    this.listeners = [];
    this.mapper = null;
    this.syncDataSources = [];
    this.isItemSource = false;
    this.itemId = '';
    this.isLazyLoad = false;
    this.poll = false;
    this.pollMS = REFRESH_TIME; // no polling
    this.pollTimeout = null;
    this.isFetching = false;
    this.syncNotified = false;
  }

  AddQuery(key, val) {
    this.queryStrings.set(key, val);
    return this;
  }

  DeleteQuery(key) {
    this.queryStrings.delete(key);
    return this;
  }

  ClearQuery() {
    this.queryStrings.clear();
    return this;
  }

  Item(id) {
    this.itemId = id; // optional, expected to be undefined if not set
    this.url = c.makeRestItemUrl(this.baseUrl);
    this.isItemSource = true;
    return this;
  }

  Lazy() {
    this.isLazyLoad = true;
    return this;
  }

  useMapper(m) {
    this.mapper = m;
    return this;
  }

  OnLoad(f) {
    this.onLoadListeners.push(f);
    return this;
  }

  Listen(f) {
    this.listeners.push(f);
    return this;
  }

  Xform(key, f) {
    this.xforms.push({ key, xform: f });
    return this;
  }

  Filter(f) {
    this.filterFunc = f;
    if (!isNil(this.data)) {
      this.notifyListeners();
    }
    return this;
  }

  Sort(f) {
    this.sortFunc = f;
    if (!isNil(this.data)) {
      this.notifyListeners();
    }
    return this;
  }

  Data() {
    let data = cloneDeep(this.data);
    if (isArray(data) && isFunction(this.filterFunc)) {
      data = data.filter(this.filterFunc);
    }
    if (isArray(data) && isFunction(this.sortFunc)) {
      data = data.sort(this.sortFunc);
    }
    return data;
  }

  Poll(ms = REFRESH_TIME) {
    if (!ms) {
      this.poll = false;
      return this;
    }

    this.poll = true;
    this.pollMS = ms;
    return this;
  }

  StartPoll() {
    this.poll = true;
    if (!this.isFetching) {
      clearTimeout(this.pollTimeout);
      this.pollTimeout = setTimeout(() => this.Fetch(), this.pollMS);
    }
  }

  StopPoll() {
    this.poll = false;
    clearTimeout(this.pollTimeout);
    this.pollTimeout = null;
  }

  Fetch(id) {
    clearTimeout(this.pollTimeout);
    this.isFetching = true;
    let { url } = this;
    debug.debug('Fetch()', id, url);

    if (this.isItemSource) {
      if (id !== undefined) {
        // for items loaded in response to GUI actions
        // via OnChange event handlers
        url = c.setId(url, id);
      } else if (
        this.itemId !== undefined ||
        (isString(this.itemId) && this.itemId.length === 0)
      ) {
        // for items loaded at page scope, e.g. for editing an existing item
        url = c.setId(url, this.itemId);
      } else {
        debug.debug('isItemSource but id is undefined!');
      }
    }
    debug.debug('GET before addQueryStrings', url);
    url = this.addQueryStrings(url);
    debug.debug('GET after  addQueryStrings', url);
    try {
      rest
        .get(url, {}, false, true) // skip REST logging for GET since we're doing enhanced logging here
        .then((response) => {
          this.setLastResult(url, response.status, '');
          debug.debug('GET', url, this.lastResult);
          return response.json();
        })
        .then((responseData) => {
          const data = this.mapper ? this.mapper(responseData) : responseData;
          this.data = assignUniqueIds(data);

          debug.debug(
            'GET:(before)',
            url,
            this.lastResult.status,
            this.lastResult.errText,
            data,
          );
          debug.debug(
            'GET:(before)',
            url,
            this.lastResult.status,
            this.lastResult.errText,
            JSON.stringify(data, null, '  '),
          );

          const filteredData = this.Data();
          let filterDebugPrefix = ' ';
          if (this.filterFunc !== undefined) {
            filterDebugPrefix = '(filtered): ';
          }

          debug.log(
            `GET:${filterDebugPrefix}`,
            url,
            this.lastResult.status,
            this.lastResult.errText,
            filteredData,
          );
          debug.debug(
            `GET:${filterDebugPrefix}`,
            url,
            this.lastResult.status,
            this.lastResult.errText,
            JSON.stringify(filteredData, null, '  '),
          );

          this.lastResult.data = filteredData;
          for (const internalOnLoadListener of this.internalOnLoadListeners) {
            debug.debug(
              'DS Fetch: internalOnLoadListeners',
              url,
              this.lastResult.status,
              this.lastResult.errText,
              filteredData,
              internalOnLoadListener,
            );
            internalOnLoadListener(this.lastResult);
          }

          this.notifyOnLoad();
          this.notifyListeners();

          debug.debug('DS Fetch: done', url);

          this.isFetching = false;

          if (this.poll) {
            this.StartPoll();
          }
        })
        .catch((err) => {
          debug.error('GET', url, 'error handler');
          rest.errorInfo(err, (errInfo) => {
            try {
              this.setLastResult(url, errInfo.status, errInfo.text);
              // this.data = {} //preserve data from previous successful fetch to mitigate intermittant connnectivity issues
              debug.error(
                'GET:',
                url,
                this.lastResult.status,
                this.lastResult.errText,
                this.data,
              );

              this.notifyOnLoad();
              this.notifyListeners();
            } catch (err) {
              debug.error('Fetch() Error: ', err);
            }

            this.isFetching = false;
            if (this.poll) {
              this.StartPoll();
            }
          });
        });
    } catch (err) {
      debug.error('Fetch() Error: ', err);
      this.isFetching = false;
      if (this.poll) {
        this.StartPoll();
      }
    }
    return this;
  }

  addQueryStrings(url) {
    const qsMap = this.queryStrings;
    debug.debug('addQueryStrings: ', url, qsMap);
    if (qsMap.size === 0) {
      return url;
    }
    let newUrl = url;
    let prefix = '?';
    for (const [key, value] of qsMap.entries()) {
      newUrl += qs.makeUrlValue(prefix, key, value);
      prefix = '&';
    }
    return newUrl;
  }

  setLastResult(url, status, errText) {
    if (isNil(this.lastResult)) {
      this.lastResult = new DataSourceResult();
    }
    this.status = status;
    this.errText = errText;

    // append to last result to ensure last result data stays populated on the lastResult
    this.lastResult.status = status;
    this.lastResult.errText = errText;
    this.lastResult.baseUrl = this.baseUrl;
    this.lastResult.url = url;
  }

  internalOnLoad(f) {
    this.internalOnLoadListeners.push(f);
    return this;
  }

  SyncNotify(dataSource) {
    this.syncDataSources.push(dataSource);
    dataSource.internalOnLoad(() => {
      // only sync notify once, not every time a secondary datasource poll updates
      if (!this.syncNotified) {
        this.notifyOnLoad();
        this.notifyListeners();
      }
    });
  }

  syncDataSourcesReady() {
    if (!isEmpty(this.syncDataSources)) {
      if (isNil(this.data)) return false; // our data not ready - trigger from syncDataSource
      for (const syncDataSource of this.syncDataSources) {
        if (isNil(syncDataSource.data)) return false; // syncDataSource not ready
      }
    }
    return true;
  }

  notifyOnLoad() {
    if (!this.syncDataSourcesReady()) {
      return;
    }
    this.syncNotified = true;

    for (const onLoadListener of this.onLoadListeners) {
      debug.debug('DS Fetch: onLoadListeners', this.lastResult, onLoadListener);
      onLoadListener({ ...this.lastResult });
    }
  }

  // notify all listeners with their xformed data
  notifyListeners() {
    if (!this.syncDataSourcesReady()) {
      return;
    }
    this.syncNotified = true;

    const data = this.Data(); // filtered data
    const result = this.lastResult;

    for (const x of this.xforms) {
      let xformedData = null;
      if (!isNil(data)) {
        xformedData = x.xform(data);
      }

      for (const notifyListener of this.listeners) {
        debug.debug(
          `DataSource:notifyListener: x.key=${x.key}`,
          notifyListener,
          xformedData,
        );
        notifyListener(x.key, {
          ...result,
          data: xformedData,
        });
      }
    }
  }

  UpDown(keyPath, direction, predicate) {
    debug.debug(`UpDown(${keyPath}, ${direction})`, predicate);

    if (!isFunction(predicate)) {
      // biome-ignore lint: <explanation>
      predicate = getUniqueIdPredicate(predicate);
    }
    if (direction !== 'up' && direction !== 'down') {
      debug.throw(
        'UpDown() error: direction invalid',
        keyPath,
        direction,
        predicate,
      );
    }

    const a = getPath(this.data, keyPath);

    if (isNil(a)) return;

    if (!isArray(a)) {
      debug.throw('Remove() error: type mismatch', keyPath, predicate);
    }

    const idx = findIndex(a, predicate);

    if (direction === 'up' && idx === 0) {
      return false;
    }
    if (direction === 'down' && idx + 1 === a.length) {
      return false;
    }
    if (direction === 'up') {
      const old = a[idx];
      a[idx] = a[idx - 1];
      a[idx - 1] = old;
      setPath(this.data, keyPath, a);
      const url = `up(${keyPath},${idx})`;
      this.setLastResult(url, 200, 'local up');
      this.notifyListeners();
      return true;
    }

    if (direction === 'down') {
      const old = a[idx];
      a[idx] = a[idx + 1];
      a[idx + 1] = old;
      setPath(this.data, keyPath, a);
      const url = `down(${keyPath},${idx})`;
      this.setLastResult(url, 200, 'local down');
      this.notifyListeners();
      return true;
    }

    return false;
  }

  Push(keyPath, value) {
    debug.debug(`Push(${keyPath})`, value);
    let a = getPath(this.data, keyPath);

    if (isNil(a)) {
      a = [];
    }

    if (!isArray(a)) {
      const err = new Error('Push() error: type mismatch', keyPath, value);
      debug.error(err);
      throw err;
    }
    a.push(value);
    setPath(this.data, keyPath, a);
    const url = `push(${keyPath},${value})`;
    this.setLastResult(url, 200, 'local push');
    this.notifyListeners();
  }

  Remove(keyPath, predicate) {
    debug.debug(`Remove(${keyPath})`, predicate);

    if (!isFunction(predicate)) {
      // biome-ignore lint: <explanation>
      predicate = getUniqueIdPredicate(predicate);
    }

    const a = getPath(this.data, keyPath);

    if (isNil(a)) return;

    if (!isArray(a)) {
      debug.throw('Remove() error: type mismatch', keyPath, predicate);
    }

    remove(a, predicate);
    setPath(this.data, keyPath, a);

    const url = `update(${keyPath},${predicate})`;
    this.setLastResult(url, 200, 'local delete');
    this.notifyListeners();
  }

  Update(keyPath, predicate, value, reset = false) {
    try {
      debug.debug(`Update(${keyPath})`, predicate);

      if (!isFunction(predicate)) {
        // biome-ignore lint: <explanation>
        predicate = getUniqueIdPredicate(predicate);
      }

      const a = getPath(this.data, keyPath);

      if (isNil(a)) return;

      if (!isArray(a)) {
        debug.throw('Update() error: type mismatch', keyPath, predicate);
      }

      const matches = a.filter(predicate);
      for (const match of matches) {
        if (reset) {
          for (const key of keys(match)) {
            // biome-ignore lint: <explanation>
            if (match.hasOwnProperty(key)) {
              delete match[key];
            }
          }
        }
        merge(match, value);
      }
      setPath(this.data, keyPath, a);

      const url = `update(${keyPath},${predicate})`;
      this.setLastResult(url, 200, 'local update');
      this.notifyListeners();
    } catch (err) {
      debug.throw(err);
    }
  }

  Set(keyPath, value) {
    debug.debug(`Set(${keyPath})`, value);
    if (isNil(this.data)) {
      // skip if no-op, which is sometimes required for DropDown() OnChange() handlers
      // that reset other InputTable data sources
      return;
    }

    setPath(this.data, keyPath, value);
    const url = `push(${keyPath},${value})`;
    this.setLastResult(url, 200, 'local push');
    this.notifyListeners();
  }

  SetData(data) {
    debug.debug('SetData()', data);
    this.data = assignUniqueIds(data);
    const url = 'setData()';
    this.setLastResult(url, 200, 'local setData');
    this.notifyListeners();
  }

  Clone(filter) {
    if (isNil(filter)) {
      return this.CloneStaticDataSource();
    }
    return new StaticDataSource(this.data.filter(filter), this.name);
  }

  CloneLinked() {
    return new LinkedDataSource(this, this.name);
  }

  CloneStaticDataSource() {
    return new StaticDataSource(this.data, this.name);
  }

  Namespace(namespace) {
    return new NamespcedDataSource(this, namespace);
  }
}

export class DataSourceResult {
  constructor(status, errText, baseUrl, url) {
    this.status = status;
    this.errText = errText;
    this.statusText = errText;
    this.baseUrl = baseUrl;
    this.url = url;
    this.data = null;
  }
}

export class NamespcedDataSource {
  constructor(ds, namespace) {
    this.ds = ds;
    this.namespace = namespace;
  }

  qualPath(key) {
    return `${this.namespace}.${key}`;
  }

  Item(id) {
    this.ds.Item(id);
    return this;
  }

  Lazy() {
    this.ds.Lazy();
    return this;
  }

  OnLoad(f) {
    this.ds.OnLoad(f);
    return this;
  }

  Xform(key, f) {
    const xform = (data) => {
      // biome-ignore lint: <explanation>
      data = get(data, this.namespace);
      return f(data);
    };

    // key = this.qualPath(key)

    this.ds.Xform(key, xform);
    return this;
  }

  Filter(f) {
    this.ds.Filter(f);
    return this;
  }

  Sort(f) {
    this.ds.Sort(f);
    return this;
  }

  Data() {
    return get(this.ds.data, this.namespace);
  }

  internalOnLoad(f) {
    this.ds.internalOnLoad(f);
    return this;
  }

  SyncNotify(dataSource) {
    this.ds.SyncNotify(dataSource);
  }

  Fetch(id) {
    this.ds.Fetch(id);
  }

  Push(keyPath, value) {
    // biome-ignore lint: <explanation>
    keyPath = this.qualPath(keyPath);
    this.ds.Push(keyPath, value);
  }

  Remove(keyPath, predicate) {
    // biome-ignore lint: <explanation>
    keyPath = this.qualPath(keyPath);
    this.ds.Remove(keyPath, predicate);
  }

  Update(keyPath, predicate, value, reset = false) {
    // biome-ignore lint: <explanation>
    keyPath = this.qualPath(keyPath);
    this.ds.Update(keyPath, predicate, value, reset);
  }

  Set(keyPath, value) {
    // biome-ignore lint: <explanation>
    keyPath = this.qualPath(keyPath);
    this.ds.Set(keyPath, value);
  }

  SetData(data) {
    set(this.ds.data, this.namespace, data);
    this.ds.SetData(this.ds.data);
  }

  CloneStaticDataSource() {
    return this.ds.CloneStaticDataSource();
  }
}

export class StaticDataSource extends DataSource {
  constructor(data, name) {
    super(`static:${name}`, name);
    this.data = assignUniqueIds(cloneDeep(data));
  }

  Fetch(id) {
    const data = this.Data();

    // for static data we don't need to fetch anything so we just immediately notify our listeners
    debug.debug('static DS Fetch()', id, this.url);

    try {
      for (const internalOnLoadListener of this.internalOnLoadListeners) {
        debug.debug(
          'DS Fetch: internalOnLoadListeners',
          this.url,
          this.lastResult,
          this.data,
          internalOnLoadListener,
        );
        internalOnLoadListener({
          ...this.lastResult,
          data,
        });
      }

      this.notifyOnLoad();
      this.notifyListeners();

      debug.debug('DS Fetch: done', this.url);
    } catch (err) {
      debug.error('static Fetch() Error: ', err);
    }
  }
}

export class LinkedDataSource extends DataSource {
  constructor(ds, name) {
    super(`linked:${name}`, name);

    if (!(ds instanceof DataSource)) {
      debug.throw('LinkedDataSource requires ds to be DataSource');
    }
    // link the OnLoad() and Fetch() together
    // but allow the static datasource to be
    this.ds = ds;
    this.data = [];
    this.ds.OnLoad((res) => {
      debug.debug('LinkedDS: OnLoad()', cloneDeep(res));
      this.data = res.data;
      if (isNil(this.data)) {
        this.data = [];
      }
      this.lastResult = res;
      this.lastResult.data = this.Data();
      this.notifyOnLoad();
      this.notifyListeners();
    });
  }

  Fetch(id) {
    debug.debug('LinkedDS: Fetch()');
    this.ds.Fetch();
  }
}

export default DataSource;
