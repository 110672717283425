// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo } from 'react';
import { Box, Spinner, Text } from 'grommet';

import { Close } from 'grommet-icons';

const LoadingItem = () => (
  <Box direction='row' gap='medium' align='center' data-testid='laodingitem'>
    <Spinner data-testid='item-spinner' size='xsmall' />
    <Text>Loading...</Text>
  </Box>
);

const LoadingError = () => {
  return (
    <Box direction='row' gap='medium' align='center' data-testid='laodingitem'>
      <Close color='red' size='small' data-testid='item-fail' />
      <Text>Some dependencies failed. Please try again later.</Text>
    </Box>
  );
};

export const LoadingServiceContainer = ({ items }) => {
  const isLoading = items.some((x) => x.isLoading);
  return (
    <Box direction='column' gap='xsmall'>
      {isLoading ? <LoadingItem /> : <LoadingError />}
    </Box>
  );
};

export const LoadingServiceStatus = ({
  setParentForm,
  queryResults,
  servicesLoading,
  children,
}) => {
  const isLoading = useMemo(
    () =>
      queryResults.some((x) => !!x.error) ||
      queryResults.some((x) => x.isLoading),
    [queryResults],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setParentForm('servicesLoading', isLoading); // Disable the Save button via a metaForm servicesLoading validator.
  }, [setParentForm, isLoading, servicesLoading !== isLoading]);

  if (isLoading) {
    return <LoadingServiceContainer items={queryResults} />;
  }

  return children;
};
