// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQueries } from 'react-query';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import {
  makeRestUrl,
  URL_STORAGE_GROUPS,
  URL_STORAGE_POOLS,
  URL_VOLUMES,
} from '../routes/consts';
import { getDefaultQueriesResponse, renderLink } from './helper';
import { byteToGb } from '../utils';
import { getVolumes } from './volumes';
import { getStorageGroups } from './storageGroups';
import TruncateText from '../components/Griddle/TruncateText';

export const BarLabel = styled(Text)`
  white-space: nowrap;
`;

const columns = ({ storageGroups, volumes }) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'capacityPoolName',
    render: renderLink({
      base: URL_STORAGE_POOLS,
      titleKey: 'capacityPoolName',
    }),
  },
  {
    header: 'Volume count',
    isDefault: true,
    property: 'totalVolumes',
    render: ({ totalVolumes }) => <Box alignSelf='start'>{totalVolumes}</Box>,
  },
  {
    header: 'Allocated capacity',
    units: 'GB',
    isDefault: true,
    property: 'used',
    render: ({ id }) => {
      const allocated = volumes
        .filter(({ capacityPoolID }) => capacityPoolID === id)
        .reduce((acc, { capacity }) => acc + capacity, 0);
      const allocatedGb = byteToGb(allocated, 'GB', true);

      return allocatedGb;
    },
  },
  {
    header: 'Latency',
    property: 'latency',
    render: ({ latency }) => latency?.replace('Latency', '') || '',
  },
  {
    header: 'Deduplicate',
    property: 'dedupeCapable',
  },
  {
    header: 'Storage group',
    property: 'storage_group_id',
    render: ({ storage_group_id }) => (
      <TruncateText
        content={storageGroups?.find(({ id }) => id === storage_group_id)?.name}
      />
    ),
  },
  {
    header: 'ID',
    property: 'id',
    render: ({ id }) => <TruncateText content={id} />,
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => <TruncateText content={toDateTime(created)} />,
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => <TruncateText content={toDateTime(modified)} />,
  },
];

export const getCapacityPools = async () => {
  const response = await get(makeRestUrl(URL_STORAGE_POOLS));

  return response.json();
};

export const useGetCapacityPools = () => {
  const queryResults = useQueries([
    {
      queryKey: URL_STORAGE_POOLS,
      queryFn: getCapacityPools,
    },
    {
      queryKey: URL_VOLUMES,
      queryFn: getVolumes,
    },
    {
      queryKey: URL_STORAGE_GROUPS,
      queryFn: getStorageGroups,
    },
  ]);

  const isLoaded = queryResults.every(
    ({ error, isLoading }) => !isLoading && !error,
  );

  if (!isLoaded) {
    return getDefaultQueriesResponse(queryResults);
  }

  const [storagePools, volumes, storageGroups] = queryResults;

  return {
    columns: columns({
      storageGroups: storageGroups.data,
      volumes: volumes.data,
    })
      // adding columnName and displayName for backwards compatibility
      .map((column) => ({
        ...column,
        columnName: column.property,
        displayName: column.header,
      })),
    data: storagePools.data,
    isLoading: false,
    refetch: () => {
      storagePools.refetch();
    },
    error: null,
  };
};
