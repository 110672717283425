// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { makeRestUrl, URL_PODS } from '../routes/consts';
import { renderLink } from './helper';
import { editColumn } from '../utils/editColumn';
import { useMembershipContext } from '../utils/context/membershipContext';

export const columns = (role) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_PODS,
    }),
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'description',
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Country',
    isDefault: true,
    property: 'location.country',
  },
  {
    header: 'Region',
    isDefault: true,
    property: 'location.region',
  },
  {
    header: 'Data center',
    isDefault: true,
    property: 'location.data_center',
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  ...editColumn({
    base: URL_PODS,
    condition: !role?.isRoleAnyViewer?.(),
    role,
  }),
];

export const getPods = async () => {
  const url = makeRestUrl(URL_PODS);
  const response = await get(url);

  return response.json();
};

export const useGetPods = (scope) => {
  const { role } = useMembershipContext();
  const queryResult = useQuery(URL_PODS, getPods, {
    enabled: scope !== 'project',
  });

  return {
    ...queryResult,
    columns: columns(role).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
