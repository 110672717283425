// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import { Box, Spinner } from 'grommet';

import CoreLayout from '../layouts/CoreLayout/CoreLayout';
import auth from '../lib/auth';
import Welcome from '../views_pub/Welcome';
import GetMemberships from '../views_pub/GetMemberships';
import Invite from '../views_pub/Invite';
import NoAuthLayout from '../layouts/CoreLayout/NoAuthLayout';
import { URL_INVITE_PUBLIC } from '../routes/consts';
import { getMembership, getRole } from '../services/membership';
import { MembershipProvider } from '../utils/context/membershipContext';
import { cleanUp, enhanceRole, useQueryParams } from '../utils';

export default function Root() {
  const [isLoading, setIsLoading] = useState(false);
  const [cachedId, setCachedId] = useState('');
  const [activeMembership, setActiveMembership] = useState({});
  const [memberships, setMemberships] = useState(null);
  const [role, setRole] = useState({});
  const [error, setError] = useState('');
  const { pathname } = useLocation();
  const params = useQueryParams();
  const history = useHistory();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    cleanUp();

    const paramId = params.get('member');
    const localId = localStorage.getItem('activeMemberID');
    const sessionId = sessionStorage.getItem('activeMemberID');
    const id = paramId || localId;

    if (id) {
      if (!sessionId) {
        auth.setActiveMemberID(id);
      }

      setCachedId(id);
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (
      !activeMembership.id &&
      pathname !== '/login' &&
      !pathname.includes('/p/') &&
      !isLoading
    ) {
      setIsLoading(true);

      getMembership()
        .then((data) => {
          let activeMember = {};

          if (data.length === 1) {
            const [firstMember] = data;
            activeMember = firstMember;
          } else if (data.length) {
            const localId = localStorage.getItem('activeMemberID');
            const cachedMembership =
              auth.getActiveMember() || data.find(({ id }) => id === localId);
            const included = data.find(({ id }) => id === cachedMembership?.id);

            if (!cachedMembership || !included) {
              const [firstMember] = data;
              activeMember = firstMember;
            } else {
              activeMember = cachedMembership;
            }
          }

          setMemberships(data);
          auth.setActiveMemberID(activeMember.id);
          auth.setActiveMember(activeMember);
          setActiveMembership(activeMember);

          return getRole(activeMember.id);
        })
        .then((data) => {
          const enhancedRole = enhanceRole(data);
          auth.setActiveScope(enhancedRole.scope);
          auth.setActiveRole(enhancedRole);
          setRole(enhancedRole);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [activeMembership, memberships, role]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const hasNoMembershipOrRoleData =
      activeMembership.id === undefined || role.id === undefined;

    if (hasNoMembershipOrRoleData) {
      return;
    }

    if (pathname.startsWith('/s/') || pathname.startsWith('/nonav/')) {
      history.push(pathname);
      return;
    }

    if (activeMembership.scope === 'project' || auth.isIAM()) {
      history.push('/s/hosts');
      return;
    }

    history.push('/s/welcome');
  }, [activeMembership, role]);

  const shouldLogin = useMemo(
    () => pathname.includes('/login') && !cachedId && !auth.securityDisabled(),
    [cachedId, pathname],
  );

  if (pathname === URL_INVITE_PUBLIC) {
    return (
      <NoAuthLayout>
        <Invite />
      </NoAuthLayout>
    );
  }

  if (pathname === '/login') {
    return <Welcome error={error} />;
  }

  if (shouldLogin) {
    history.push('/login');

    return null;
  }

  if (isLoading) {
    return (
      <Box fill align='center' justify='center'>
        <Spinner />
      </Box>
    );
  }

  if (memberships?.length === 0) {
    return <GetMemberships />;
  }

  if (activeMembership.id) {
    return (
      <MembershipProvider
        activeMembership={activeMembership}
        memberships={memberships}
        role={role}
      >
        <BrowserRouter>
          <CoreLayout />
        </BrowserRouter>
      </MembershipProvider>
    );
  }

  return null;
}
