// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { SCOPE_HOSTER, SCOPE_PORTAL, SCOPE_PROJECT } from '../routes/consts';

export const cleanUp = () => {
  // clean up client-side login artifacts
  localStorage.getItem('idToken') && localStorage.removeItem('idToken');
  localStorage.getItem('accessToken') && localStorage.removeItem('accessToken');
  localStorage.getItem('userToken') && localStorage.removeItem('userToken');
  localStorage.getItem('userProfile') && localStorage.removeItem('userProfile');
};

export const enhanceRole = (data) => {
  return {
    ...data,
    isRoleProjectOwner: function () {
      return this.id === 'project_owner';
    },
    isRoleProjectViewer: function () {
      return this.id === 'project_viewer';
    },
    isRoleHosterOwner: function () {
      return this.id === 'hoster_owner';
    },
    isRoleHosterViewer: function () {
      return this.id === 'hoster_viewer';
    },
    isRoleAnyViewer: function () {
      return this.id.includes('_viewer');
    },
    isScopeHoster: function () {
      return this.scope === SCOPE_HOSTER;
    },
    isScopeProject: function () {
      return this.scope === SCOPE_PROJECT;
    },
    isScopePortal: function () {
      return this.scope === SCOPE_PORTAL;
    },
  };
};
