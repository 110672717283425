// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import { LABEL_ORGANIZATIONS } from '../../components/HybridNav/consts.js';

const EXTERNAL_NAME = c.URL_ORGANIZATIONS;

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_ORGANIZATIONS,
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
};

const DS_ORGANIZATIONS = 'organizations';

function metaCreate(props) {
  const m = new MetaBuilder(props);

  const id = m.view.prop('itemId', false);

  // let ds //TODO?
  // let createView
  if (id === undefined) {
    // createView = true
    /* ds = */ m.newStaticDataSource({}, DS_ORGANIZATIONS);
  } else {
    // createView = false
    /* ds = */ m.newDataSource(c.URL_ORGANIZATIONS, DS_ORGANIZATIONS)
      .Item(id)
      .OnLoad((json) => {
        m.view.initForm(json.data);
      });
  }

  const authMethods = [{ id: 'greenlake', name: 'Greenlake IAM' }];
  const dsAuthMethods = m.newStaticDataSource(authMethods);

  m.addColumn('name', 'Name').Input();

  m.addColumn('auth_method', 'Auth method')
    .Input()
    .DropDown()
    .DataXform(dsAuthMethods, (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    );

  m.addColumn('tenant_id', 'Tenant ID').Input();

  return m;
}

export { default as OrganizationListView } from './OrganizationListView';

export const OrganizationItemView = ItemViewContainer({
  ...settings,
  allowEdit: true,
  meta: (props) => metaCreate(props), // use edit meta
  title: 'Organization',
});

export const OrganizationCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create organization',
});

export const OrganizationEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Edit organization',
});
