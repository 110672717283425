// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React, { useCallback, useState } from 'react';
import { useQueries, useQuery } from 'react-query';

import { toDateTime } from '../lib/formatters';
import { get, post } from '../lib/rest';
import {
  makeRestUrl,
  URL_AVAIL_RESOURCES,
  URL_HOSTS,
  URL_PROJECTS,
} from '../routes/consts';
import {
  getDefaultQueriesResponse,
  getHostMachineInfo,
  MachineActions,
  progressMapFunc,
  renderLink,
  Status,
} from './helper';
import { getProjects } from './projects';
import { elapsed } from '../lib/formatters';
import { ProgressBar } from '../components/Griddle/ColumnProgressBar';
import { getAlerts } from './alerts';
import TruncateText from '../components/Griddle/TruncateText';
import { editColumn } from '../utils/editColumn';
import { useMembershipContext } from '../utils/context/membershipContext';

export const getAvailableResources = async (projectId) => {
  const params =
    projectId && projectId !== '__self' ? { project: projectId } : {};

  const response = await get(makeRestUrl(URL_AVAIL_RESOURCES), params);
  return response.json();
};

export const useGetAvailableResources = (projectId) => {
  const queryResults = useQuery({
    queryKey: [URL_AVAIL_RESOURCES, projectId],
    queryFn: () => getAvailableResources(projectId),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return queryResults;
};

export const createHost = async (data) => {
  const response = await post(makeRestUrl(URL_HOSTS), data);
  if (response.ok) {
    return response.json();
  }
  throw response.text();
};

export const useCreateHost = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [host, setHost] = useState(null);

  const execute = async (requestBody) => {
    try {
      setIsLoading(true);
      setError(null);
      const data = await createHost(requestBody);
      setHost(data);
      setIsLoading(false);
      return data;
    } catch (e) {
      setError(e);
      setIsLoading(false);
      throw e;
    }
  };

  return {
    isLoading,
    error,
    host,
    createHost: useCallback(
      execute,
      [], // avoid infinite callbacks in useEffect
    ),
  };
};

export const columns = ({ addAlert, projects, role }) => [
  {
    header: 'Alert',
    property: 'alert',
    render: ({ alerts: { content, status, tooltip } }) => (
      <Status content={content} status={status} tooltip={tooltip} />
    ),
  },
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_HOSTS,
    }),
  },
  {
    header: 'ID',
    property: 'id',
    render: ({ id }) => <TruncateText content={id} />,
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'description',
  },
  ...(role?.isScopeHoster?.()
    ? [
        {
          header: 'Project name',
          isDefault: true,
          property: 'project_id',
          render: (data) => {
            const project = projects?.find(({ id }) => id === data.project_id);

            return project?.name || '';
          },
        },
      ]
    : []),
  {
    header: 'Machine size',
    isDefault: true,
    property: 'machine_size_name',
  },
  {
    header: 'Comm status',
    isDefault: true,
    property: 'portal_comm_okay',
    render: ({ portal_comm_okay: okay }) => {
      const content = okay ? 'OK' : 'NC';
      const status = okay ? '' : 'status-warning';
      const tooltip = okay ? '' : 'Management connection lost to this host';

      return <Status content={content} status={status} tooltip={tooltip} />;
    },
  },
  {
    header: 'Workflow',
    isDefault: true,
    property: 'workflow',
  },
  {
    header: 'State',
    isDefault: true,
    property: 'state',
  },
  {
    header: 'Step',
    isDefault: true,
    property: 'substate',
  },
  {
    header: 'Elapsed',
    isDefault: true,
    property: 'substate_time',
    render: ({ substate_time: time }) => elapsed(time),
  },
  {
    header: 'Progress',
    isDefault: true,
    property: 'progress',
    render: ({ progress, state, substate }) => {
      const result = progressMapFunc({ progress, state, substate });

      return <ProgressBar progress={result.progress} status={result.style} />;
    },
  },
  ...(role?.isRoleAnyViewer?.()
    ? []
    : [
        {
          header: 'Actions',
          isDefault: true,
          property: 'guiactions',
          searchDisabled: true,
          render: (data) => {
            if (!data) {
              return null;
            }

            const info = getHostMachineInfo(data, addAlert);
            const disabled = info.portalCommOkay === false;

            return <MachineActions disabled={disabled} info={info} />;
          },
        },
      ]),
  ...editColumn({ base: URL_HOSTS, role }),
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Image Svc ID',
    property: 'svc_id',
  },
  {
    header: 'OS flavor',
    property: 'svc_flavor',
  },
  {
    header: 'OS version',
    property: 'svc_version',
  },
  {
    header: 'State started',
    property: 'state_time',
    render: ({ state_time }) => toDateTime(state_time),
  },
  {
    header: 'Step started',
    property: 'substate_time',
    render: ({ substate_time }) => toDateTime(substate_time),
  },
  {
    header: 'Power',
    property: 'power_status',
  },
  {
    header: 'Location ID',
    property: 'location_id',
  },
  {
    header: 'Country',
    property: 'location.country',
  },
  {
    header: 'Region',
    property: 'location.region',
  },
  {
    header: 'DataCenter',
    property: 'location.data_center',
  },
  {
    header: 'SSH keys',
    property: 'ssh_authorized_keys',
  },
  {
    header: 'Networks',
    property: 'networks',
  },
  {
    header: 'Deleted',
    property: 'deleted',
    render: (data) => (data.deleted ? 'yes' : 'no'),
  },
  {
    header: 'Portal ID',
    property: 'portal_id',
  },
  {
    header: 'Hoster ID',
    property: 'hoster_id',
  },
  {
    header: 'Machine size ID',
    property: 'machine_size_id',
  },
  {
    header: 'Machine ID',
    property: 'machine_id',
  },
];

export const getHosts = async (apiParams = {}) => {
  const response = await get(makeRestUrl(URL_HOSTS), apiParams);
  return response.json();
};

export const useGetHosts = ({ addAlert, scope, apiParams }) => {
  const { role } = useMembershipContext();
  const queryResults = useQueries([
    {
      queryKey: [URL_HOSTS, apiParams],
      queryFn: () => getHosts(apiParams),
    },
    {
      queryKey: URL_PROJECTS,
      queryFn: getProjects,
    },
    {
      queryKey: 'HOST_ALERTS',
      queryFn: () => getAlerts({ source_type: 'hosts' }),
      enabled: scope === 'hosters',
    },
  ]);

  const isLoaded = queryResults.every(
    ({ error, isLoading }) => !isLoading && !error,
  );

  if (isLoaded) {
    const [hosts, projects, alerts] = queryResults;
    return {
      columns: columns({
        addAlert,
        projects: projects.data,
        role,
      })
        // adding columnName and displayName for backwards compatibility
        .map((column) => ({
          ...column,
          columnName: column.property,
          displayName: column.header,
        })),
      data:
        hosts.data?.map((host) => {
          const filteredAlerts =
            alerts?.data?.filter(
              ({ source_id: sourceId }) => sourceId === host.id,
            ) || [];
          const count = filteredAlerts.length;
          const content = count || '';
          const status = count ? 'status-warning' : '';
          const plural = `alert${count === 1 ? '' : 's'}`;
          const tooltip = count ? `${count} active ${plural}` : '';

          return {
            ...host,
            alerts: {
              content,
              status,
              tooltip,
            },
            networks: host?.networks?.join(', ') || '',
          };
        }) ?? [],
      isLoading: false,
      refetch: (isPolling = false) => {
        if (isPolling) {
          hosts.refetch();
        } else {
          hosts.refetch();
          projects.refetch();
        }
      },
      error: null,
    };
  }

  return getDefaultQueriesResponse(queryResults);
};
