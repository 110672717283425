// (C) Copyright 2022, 2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { makeRestUrl, URL_IPPOOLS } from '../routes/consts';
import { renderLink } from './helper';
import TruncateText from '../components/Griddle/TruncateText';
import { editColumn } from '../utils/editColumn';
import { useMembershipContext } from '../utils/context/membershipContext';

export const columns = (role) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_IPPOOLS,
    }),
  },
  {
    header: 'ID',
    property: 'id',
    render: ({ id }) => <TruncateText content={id} />,
  },
  {
    header: 'Description',
    property: 'description',
    isDefault: true,
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  ...editColumn({
    base: URL_IPPOOLS,
    role,
    condition: !role?.isRoleAnyViewer?.(),
  }),
];

export const getIpPools = async () => {
  const response = await get(makeRestUrl(URL_IPPOOLS));

  return response.json();
};

export const useGetIpPools = () => {
  const { role } = useMembershipContext();
  const { data, error, isLoading, isError, refetch } = useQuery(
    URL_IPPOOLS,
    getIpPools,
  );

  return {
    columns: columns(role).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
    data,
    error,
    isError,
    isLoading,
    refetch,
  };
};
