// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { makeRestUrl, URL_TAGS } from '../routes/consts';
import { editColumn } from '../utils/editColumn';
import { useMembershipContext } from '../utils/context/membershipContext';

export const columns = (role) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
  },
  {
    header: 'Purpose',
    isDefault: true,
    property: 'purpose',
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  ...editColumn({ base: URL_TAGS, role }),
];

export const getTags = async () => {
  const response = await get(makeRestUrl(URL_TAGS));

  return response.json();
};

export const useGetTags = () => {
  const { role } = useMembershipContext();
  const queryResult = useQuery(URL_TAGS, getTags);

  return {
    ...queryResult,
    columns: columns(role).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
